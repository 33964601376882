import React, { Component } from "react";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone-uploader";
import axios from "axios";
import config from "data/config";

class MyDropZone extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleChangeStatus = this.handleChangeStatus.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getUploadParams = this.getUploadParams.bind(this);

    // array to track uploaded files to their corresponding AWS URLs
    this.fileMap = [];

    // array to track all the successfully uploaded files 
    this.uploadedFiles = [];
  }

  handleChangeStatus({ meta, file }, status) {
    if (
      [
        "rejected_file_type",
        "rejected_max_files",
        "error_file_size",
        "error_validation",
        "error_upload_params",
        "exception_upload",
        "error_upload"
      ].indexOf(status) !== -1
    ) {
      this.fileMap[file.name] = null;
      console.log(status);
    } else if (status === "done") {
      this.uploadedFiles.push(this.fileMap[file.name]);
      this.props.onUpload(this.uploadedFiles);
    } else if (status === "removed") {
      this.uploadedFiles = this.uploadedFiles.filter((item) => item !== this.fileMap[file.name]);
      this.fileMap[file.name] = null;
      this.props.onUpload(this.uploadedFiles);
    }
    //console.log(status, file.name);
  }

  handleSubmit(files) {
    console.log(files.map(f => f.meta));
    console.log(this.uploadedFiles, this.fileMap);
  }

  getUploadParams = async ({ file, meta: { name } }) => {
    const { fileUrl, uploadUrl } = await axios
      .post(config.API_ENDPOINT + `/api/files/${this.props.accessType}`, {
        fileName: file.name,
        fileType: file.type,
        serviceType: this.props.uploadType
      })
      .then(res => res.data)
      .catch(err => console.log(err));
    if (fileUrl) this.fileMap[file.name] = fileUrl;
    console.log(file, uploadUrl, fileUrl);
    return { body: file, method: "PUT", meta: { fileUrl }, url: uploadUrl };
  };

  render() {
    return (
      <Dropzone
        getUploadParams={this.getUploadParams}
        onChangeStatus={this.handleChangeStatus}
        onSubmit={this.handleSubmit}
        inputContent={this.props.placeHolder}
        //inputWithFilesContent = ''
        //submitButtonContent = {null}
        accept={this.props.acceptTypes}
        canRemove={this.props.canRemove}
        styles={{
          dropzone: {
            overflow: "hidden",
            border: "1px dashed #aaa",
            minHeight: this.props.height
          },
          submitButtonContainer: {
            display: "none"
          },
          inputLabel: {
            textAlign: "center",
            color: "#999",
            fontWeight: "500",
            fontSize: "1.1rem",
          }
        }}
        //PreviewComponent={props => <h6>Drag Images here or Click to Browse</h6>}
        //SubmitButtonComponent={props => ''}
        //LayoutComponent={props => <h3>dsdfdsf</h3>}
      />
    );
  }
}

MyDropZone.defaultProps = {
  placeHolder: "Drag Images here or Click to browse",
  accessType: "public",
  acceptTypes: "image/*",
  height: 200,
  canRemove: true,
};

MyDropZone.propTypes = {
  onUpload: PropTypes.func.isRequired,
  placeHolder: PropTypes.string,
  accessType: PropTypes.string,
  acceptTypes: PropTypes.string,
  height: PropTypes.number,
  canRemove: PropTypes.bool,
  uploadType: PropTypes.string.isRequired
};

export default MyDropZone;
