import React, { Component } from "react";
import BookAppointment from "pages/components/BookAppointment";
import { getSubDomain } from "utils";
import { getVendorSiteContent } from "./network";

export default class SubHome extends Component {
    constructor(props) {
        super(props);
        const subDomain = getSubDomain();
        this.state = {
            subDomain,
            header: "Header Section",
            body: "Body"
        };
        if (subDomain) {
            getVendorSiteContent(subDomain.id)
                .then(r => {
                    const [sd] = r.data.websites;
                    if (sd) {                        
                        const {
                            header_section,
                            body_section,
                        } = sd;
                        // put space in empty tags otherwise blank lines wont render
                        const header = header_section.replace("></", ">&nbsp;</");
                        const body = body_section.replace("></", ">&nbsp;</");
                        
                        this.setState({ header, body });
                    }
                })
                .catch(err => console.log(err));
        }
    }
    render() {
        const { subDomain, header, body } = this.state;
        return (
            <div>
                <div className="container">
                    {/* #Banner */}
                    <div className="" style={{marginTop: "6em"}}>
                        <p
                                dangerouslySetInnerHTML={{
                                    __html: header
                                }}
                            ></p> 
                    </div>
                    {/*Services*/}
                    <section className=" p-lg-4">
                        <div className="container">
                            <h4 className="text-center text-muted py-3">
                                Book an Appointment
                            </h4>
                            <div className="mt-4 p-4 shadow-lg">
                                <BookAppointment
                                    vendor_id={subDomain.vendor_id}
                                />
                            </div>
                        </div>
                    </section>
                    {/*Body Section*/}
                    <section className="py-5">
                        <div
                            className="container my-4"
                            style={{ paddingBottom: "12em" }}
                        >
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: body
                                }}
                            ></p>
                        </div>
                    </section>
                    {/*Footer*/}
                </div>
            </div>
        );
    }
}
