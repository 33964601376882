export const TEST_DISPATCH = 'TEST_DIPATCH';

// Config actions
export const RA_CONFIG_SET_CALPICKER = 'RA_CONFIG_SET_CALPICKER';
export const RA_CONFIG_SET_CHECKOUT_DATA = 'RA_CONFIG_SET_CHECKOUT_DATA';

// auth actions
export const RA_LOGIN = "RA_LOGIN"


export const RA_UPDATE_USER_PROFILE = "RA_UPDATE_USER_PROFILE"
export const RA_SET_CURRENT_USER = "RA_SET_CURRENT_USER"

// for updaing subdomain info
export const RA_UPDATE_SUBDOMAIN_INFO = "RA_UPDATE_SUBDOMAIN_INFO"

// Public - Search , list , details

export const PUBLIC_SEARCH_PARAMS = 'PUBLIC_SEARCH_PARAMS';
export const PUBLIC_GET_DETAILS = 'PUBLIC_GET_DETAILS';