
const text = {
    type: 'text'
};

const notes = {
    type: 'text-area'
};

const select = {
    type: 'dropdown'
};

const file = {
    type: 'file'
};

const date = {
    type: 'date' 
};

const required = {
    required: true
}

const visible = {
    visible: true
}

const hd_items = [
    { name: "name", title: "Name", ...text, ...visible, ...required },
    { name: "date", title: "Date", ...date, ...visible, ...required },
    { name: "files", title: "Files", ...file,  ...required },
    { name: "comment", title: "Comment", ...notes },
];

// visit types
const sel_visit_type = [
    { value: "BandageChange", label: "Bandage change" },
    { value: "Behavior", label: "Behavior" },
    { value: "CBCCHEM", label: "CBC/CHEM" },
    { value: "CSUVisit", label: "CSU visit" },
    { value: "Cytology", label: "Cytology" },
    { value: "Dental", label: "Dental" },
    { value: "Diagnostics", label: "Diagnostics" },
    { value: "Evaluation", label: "Evaluation" },
    { value: "Exam", label: "Exam" },
    { value: "FecalExam", label: "Fecal exam" },
    { value: "FELVTest", label: "FELV test" },
    { value: "FELVFIVTest", label: "FELV/FIV test" },
    { value: "FIVTest", label: "FIV test" },
    { value: "FosterExam", label: "Foster exam" },
    { value: "FosterVisit", label: "Foster Visit" },
    { value: "GroomingBathing", label: "Grooming/Bathing" },
    { value: "HeartwormTest", label: "Heartworm test" },
    { value: "Histo", label: "Histo" },
    { value: "IntakeProcedure", label: "Intake procedure" },
    { value: "MedNotes", label: "Med notes" },
    { value: "MedicalGroom", label: "Medical groom" },
    { value: "Radiographs", label: "Radiographs" },
    { value: "RTOSpayNeuter", label: "RTO spayneuter" },
    { value: "SAST", label: "SAST" },
    { value: "SkinScrape", label: "Skin scrape" },
    { value: "SNAPPSpayNeuter", label: "SNAPP spayneuter" },
    { value: "SpayNeuterSurgery", label: "Spay neuter surgery" },
    { value: "Surgery", label: "Surgery" },
    { value: "UA", label: "U/A" },
    { value: "VaccDeworm", label: "Vacc deworm" },
    { value: "Weight", label: "Weight" },
    { value: "Wildlife", label: "Wildlife" },
]; 

// medication status
const sel_med_status = [
    { value: "Activ", label: "Active" },
    { value: "Discontinued", label: "Inactive" },
    { value: "Unknown", label: "Unknown" },
]

// yes/no
const sel_yes_no = [
    { value: "True", label: "Yes" },
    { value: "False", label: "No" },
]

// notes types
const sel_hd_notes = [
    { value: "ConsultationNote", label: "Consultation Note" },
    { value: "ProgressNote", label: "Progress Note" },
]

// imaging services
const sel_hd_imaging = [
    { value: "XRay", label: "XRay" },
    { value: "CTScan", label: "CT Scan" },
    { value: "MRIEndoscopy", label: "MRI Endoscopy" },
    { value: "Ultrasound", label: "Ultrasound" },
    { value: "Other", label: "Other" },
]

// lab services
const sel_hd_lab = [
    { value: "DopplerStudies", label: "Doppler Studies" },
    { value: "Hematology", label: "Hematology" },
    { value: "Panels", label: "Panels" },
    { value: "Biochemistry", label: "Biochemistry" },
    { value: "Biopsy", label: "Biopsy" },
]

// main menu 
export const PHR_MAIN_MENU = [
    {
        id: "history",
        title: "Health History",
        icon: "fa-history",
        sub: [
            { id: "hh_cond", title: "Conditions" },
            { id: "hh_surgeries", title: "Surgeries/Procedures" },
            { id: "hh_medications", title: "Medications" },
            { id: "hh_allergies", title: "Allergies" },
            { id: "hh_immunizations", title: "Immunizations" },
            { id: "hh_foodplan", title: "Food Plan" },
            { id: "hh_hostpitalizations", title: "Hostpitalizations" }, 
            { id: "hh_consultations", title: "Consultations" },
        ]
    },
    {
        id: "tracker",
        title: "Health Trackers",
        icon: "fa-calendar-plus-o",
        sub: [
            { id: "ht_height", title: "Height" },
            { id: "ht_weight", title: "Weight" }, 
            { id: "ht_pulse", title: "Pulse" },
            { id: "ht_temperature", title: "Temperature" },
            { id: "ht_bp", title: "Blood Pressure" },
            { id: "ht_cholestrol", title: "Cholestrol/Lipid" },
            { id: "ht_cbg", title: "CBG" },
            { id: "ht_hemoglobin", title: "Hemoglobin" },
            { id: "ht_hemogram", title: "Hemogram" },
            { id: "ht_serumel", title: "Serum Electrolytes" }, 
        ]
    },
    {
        id: "documents",
        title: "Health Documents",
        icon: "fa-book",
        sub: [
            { id: "hd_notes", title: "Notes" },
            { id: "hd_imaging", title: "Imaging Services" },
            { id: "hd_cardio", title: "Cardiovascular " },
            { id: "hd_visits", title: "Hospital Visits" },
            { id: "hd_procedures", title: "Procedures/Surgeries" },
            { id: "hd_lab", title: "Laboratory" },
            { id: "hd_insurance", title: "Insurance" },
            { id: "hd_otherdiag", title: "Other diagnostics" },
            { id: "hd_misc", title: "Misc" },
            { id: "hd_prescriptions", title: "Prescriptions" },
        ]
    },
];

export const PHR_MENU_CONTENT = {
    hh_cond: [
        { name: "name", title: "Pathology Name", ...text, ...visible, ...required },
        { name: "type", title: "Visit Type", ...select, ...visible, ...required, data: sel_visit_type }, 
        { name: "visit_date", title: "Visit Date", ...date, ...visible, ...required },
        { name: "end_date", title: "End Date", ...date, ...visible,  },
        { name: "comment", title: "Comment", ...notes },
    ],

    hh_surgeries: [
        { name: "name", title: "Surgery", ...text, ...visible, ...required },
        { name: "date", title: "Date of Surgery", ...date, ...visible, ...required },
        { name: "reason", title: "Reason", ...text, ...visible, ...required },
        { name: "doc", title: "Doctor", ...text, ...visible,  },
        { name: "comment", title: "Comment", ...notes },
    ],

    hh_medications: [
        { name: "name", title: "Medication Name", ...text, ...visible, ...required },
        { name: "reason", title: "Reason", ...text, ...visible,  },
        { name: "status", title: "Status", ...select, ...visible, data: sel_med_status },
        { name: "date", title: "Visit Date", ...date, ...visible, ...required },
        { name: "duration", title: "Duration", ...text, placeHolder: "Enter Duration in Days" },
        { name: "frequency", title: "Frequency", ...text },
        { name: "dosage", title: "Dosage", ...text },
        { name: "medium", title: "Medium", ...text },
        { name: "doc", title: "Doctor", ...text },
        { name: "comment", title: "Comment", ...notes },
    ],

    hh_allergies: [
        { name: "name", title: "Allergy", ...text, ...visible, ...required },
        { name: "start_date", title: "Start Date", ...date, ...visible,  },
        { name: "end_date", title: "End Date", ...date, ...visible,  },
        { name: "reaction", title: "Reaction", ...text, ...visible, ...required },
        { name: "comment", title: "Comment", ...notes },
    ],

    hh_immunizations: [
        { name: "name", title: "Immunization", ...text, ...visible, ...required },
        { name: "date", title: "Injection Date", ...date, ...visible, ...required },
        { name: "due_date", title: "Due Date", ...date, ...visible,  },
        { name: "hospital", title: "Hospital Name", ...text },
        { name: "serial", title: "Serial Number", ...text, ...visible,  },
        { name: "comment", title: "Comment", ...notes },
    ],

    hh_foodplan: [
        { name: "name", title: "Food Name", ...text, ...visible, ...required },
        { name: "rec", title: "Recommended ", ...select, ...visible, data: sel_yes_no },
        { name: "forbidden", title: "Forbidden", ...select, ...visible, data: sel_yes_no  },
        { name: "comment", title: "Comment", ...notes },
    ],

    hh_hostpitalizations: [
        { name: "hospital", title: "Hospital Name", ...text, ...visible, ...required },
        { name: "reason", title: "Reason", ...text, ...visible, ...required },
        { name: "start_date", title: "Admission Date", ...date, ...visible,  },
        { name: "end_date", title: "End Date", ...date, ...visible,  },
        { name: "urgent_care", title: "Urgent Care Visit", ...select, data: sel_yes_no },
        { name: "comment", title: "Comment", ...notes },
    ], 

    hh_consultations: [
        { name: "name", title: "Consultation Name", ...text, ...visible, ...required },
        { name: "visit_date", title: "Visit Date", ...date, ...visible, ...required },
        { name: "reason", title: "Reason", ...text, ...visible,  },
        { name: "comment", title: "Comment", ...notes },
    ],


    ht_height: [
        { name: "date", title: "Date", ...date, ...visible, ...required },
        { name: "ft", title: "Feet", ...text, ...visible, ...required, placeHolder: "in feets" },
        { name: "in", title: "Inches", ...text, ...visible, placeHolder: "in inches" },
    ],

    ht_weight: [
        { name: "date", title: "Date", ...date, ...visible, ...required },
        { name: "lbs", title: "Lbs", ...text, ...visible, ...required, placeHolder: "in pounds" },
        { name: "oz", title: "Oz", ...text, ...visible, placeHolder: "in Oz" },
    ],

    ht_pulse: [
        { name: "date", title: "Date", ...date, ...visible, ...required },
        { name: "pulse", title: "Pulse", ...text, ...visible, ...required, placeHolder: "Enter pulse in BPM" },
    ],

    ht_temperature: [
        { name: "date", title: "Date", ...date, ...visible, ...required },
        { name: "fahrenheit", title: "Fahrenheit", ...text, ...visible, ...required, placeHolder: "in fahrenheit" },
    ],

    ht_bp: [
        { name: "date", title: "Date", ...date, ...visible, ...required },
        { name: "systolic", title: "Systolic", ...text, ...visible, ...required, placeHolder: "Systolic (mmHg)" },
        { name: "diastolic", title: "Diastolic", ...text, ...visible, ...required, placeHolder: "Diastolic (mmHg)" },
    ],

    ht_cholestrol: [
        { name: "date", title: "Date", ...date, ...visible, ...required, },
        { name: "total_chol", title: "Total Cholestrol", ...text, ...visible, ...required, placeHolder:"Enter in (mg/dL)" },
        { name: "hdl", title: "HDL", ...text, ...visible, ...required, placeHolder:"Enter in (mg/dL)" },
        { name: "ldl", title: "LDL", ...text, ...visible, ...required, placeHolder:"Enter in (mg/dL)" },
        { name: "triglycerides", title: "Triglycerides", ...text, placeHolder:"Enter in (mg/dL)" },
        { name: "vldl", title: "VLDL", ...text, placeHolder:"Enter in (mg/dL)" },
        { name: "chol_ratio", title: "Cholestrol Ratio", ...text, },
    ],

    ht_cbg: [
        { name: "date", title: "Date", ...date, ...visible, ...required },
        { name: "cbg", title: "CBG", ...text, ...visible, ...required, placeHolder: "Enter CBG in g/L" },
    ],

    ht_hemoglobin: [
        { name: "date", title: "Date", ...date, ...visible, ...required },
        { name: "hemoglobin", title: "Hemoglobin", ...text, ...visible, ...required, placeHolder: "Enter hemoglobin in %" },
    ],

    ht_hemogram: [
        { name: "date", title: "Date", ...date, ...visible, ...required },
        { name: "hemogram", title: "Hemogram", ...text, ...visible, ...required, placeHolder: "Enter hemogram in g/L" },
    ],

    ht_serumel: [
        { name: "date", title: "Date", ...date, ...visible, ...required },
        { name: "serum", title: "Serum Electrolyte", ...text, ...visible, ...required, placeHolder: "Enter in g/L" },
    ],


    hd_notes: [
        { name: "type", title: "Type", ...select, ...required, data: sel_hd_notes },
        { name: "other", title: "Type Info", ...text, placeHolder:"[Optional]" },   
        ...hd_items,      
    ],

    hd_imaging: [
        { name: "type", title: "Type", ...select, ...required, data: sel_hd_imaging }, 
        { name: "other", title: "Type Info", ...text, placeHolder:"[Optional]" },  
        ...hd_items, 
    ],

    hd_cardio: [
        ...hd_items
    ],

    hd_visits: [
        ...hd_items
    ],

    hd_procedures: [
        ...hd_items
    ],

    hd_lab: [
        { name: "type", title: "Type", ...select, ...required, data: sel_hd_lab }, 
        { name: "other", title: "Type Info", ...text, placeHolder:"[Optional]" },  
        ...hd_items, 
    ],

    hd_insurance: [
        ...hd_items
    ],

    hd_otherdiag: [
        ...hd_items
    ],

    hd_misc: [
        ...hd_items
    ],

    hd_prescriptions: [
        ...hd_items
    ], 

};





