import { RA_CONFIG_SET_CALPICKER, RA_CONFIG_SET_CHECKOUT_DATA, RA_UPDATE_SUBDOMAIN_INFO } from "./types";

// Register User
export const setCalendarPickerDate = timestamp => {
  return {
    type: RA_CONFIG_SET_CALPICKER,
    payload: timestamp
  };
};

export const setCheckoutPayload = data => {
  return {
    type: RA_CONFIG_SET_CHECKOUT_DATA,
    payload: data
  }
}

// set subdomain info
export const updateSubDomainInfo = payload => {
  return {
    type: RA_UPDATE_SUBDOMAIN_INFO,
    payload
  }
}

