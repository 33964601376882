import React, { Component } from "react";
import { PHR_MAIN_MENU, PHR_MENU_CONTENT } from "data/config/phr";
import isEmpty from "utils/isEmpty";
import { createOrUpdate, getPHR, deletePHR } from "./network";
import Button from "pages/components/form/Button";
import CRUDIndex from "../../../components/CRUD";
import { getUserId } from "utils";

export default class PHRIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userId: this.props.userId || getUserId(),
            petId: this.props.petId || null,
            data: null,
            dataLoading: true,
            breacrumb: null,
            selMenuId: null,
            selMenuContent: null, 
        };

        this.setMenu = this.setMenu.bind(this);
        this.onUpdateRow = this.onUpdateRow.bind(this);
        this.onDeleteRow = this.onDeleteRow.bind(this);
        this.fetchData();
    }

    fetchData() {
        const { userId, petId } = this.state;
        getPHR(userId, petId)
            .then(r => {
                const data = r.data.phr;                
                this.setState({ data, dataLoading: false });
                this.setMenu( 'hh_cond', 'Health History / Conditions'); // initial data
            })
            .catch(err => console.log(err));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.petId !== this.props.petId)
            this.setState({ petId: this.props.petId, selMenuId: null, dataLoading: true }, this.fetchData);
    }

    setMenu (selMenuId, breacrumb) {
        this.setState({
            breacrumb,
            selMenuId,
            selMenuContent: PHR_MENU_CONTENT[selMenuId],
        });
    }

    onDeleteRow(id) {
        return deletePHR(id)
            .then(r => {
                const { data } = this.state;
                const filteredItems = data.filter(i => i.id !== id);
                this.setState({ data: filteredItems });
            })
            .catch(err => console.log(err));
    }

    onUpdateRow(id, payload) {
        const { data, selMenuId, userId, petId } = this.state;

        // update records in db
        return createOrUpdate(id, petId, userId, selMenuId, payload)
            .then(r => {
                if (id) {
                    const [et] = data.filter(i => i.id === id);
                    if (et) et.details = { ...payload };
                } else {
                    const [row] = r.data.insert_phr.returning;
                    const v = {
                        id: row.id,
                        category: selMenuId,
                        details: { ...payload }
                    };
                    data.push(v); 
                    this.setState({data: [...data]})
                }
            })
            .catch(err => console.log(err));
    }

    render() {
        // return loading if the data is still being fetched
        if (this.state.dataLoading) return <p className="text-mute">loading...</p>;

        const { data, breacrumb, selMenuContent, selMenuId } = this.state;

        // get data based on category
        const tblData = data.filter(d => d.category === selMenuId);

        const showCount = category => {
            const ar = data.filter(d => d.category === category);
            if (!isEmpty(ar)) return <span className="ml-1 font-weight-bold">({ar.length})</span>;
        };

        return (
            <div className="p-2 shadow-sm">
                <nav id="phr-nav" className="mt-1" aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="my-auto  mr-3 dropdown" data-toggle="dropdown" aria-expanded="false">
                            <Button btnCls="btn-outline-secondary" iconCls="fa-bars" />
                        </li>
                        <div className="ml-2 w-75  dropdown-menu">
                            <div className="p-3 bg-white row m-0 ">
                                {PHR_MAIN_MENU.map((menu, k) => (
                                    <div key={k} className="col-md-4 col-sm-12 mt-2 ">
                                        <div className="mb-3 mr-4" style={{ borderBottom: "2px solid #ccc" }}>
                                            <p className="h5 text-muted">
                                                <i className={`fa ${menu.icon} mr-2`}></i>
                                                {menu.title}
                                            </p>
                                        </div>
                                        <div className="d-flex flex-column">
                                            {menu.sub.map((item, k1) => (
                                                <p
                                                    key={k1}
                                                    className="h6 mt-1 text-muted"
                                                    parent-name={menu.title}
                                                    parent-id={menu.id}
                                                    my-id={item.id}
                                                    onClick={e=>{
                                                        const id = e.target.getAttribute(item.id);
                                                        this.setMenu(item.id, `${menu.title} / ${item.title}`);
                                                    }}
                                                    style={{ cursor: "pointer" }}
                                                >
                                                    {item.title} <span>{showCount(item.id)}</span>{" "}
                                                </p>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <li className="my-auto " aria-current="page">
                            {selMenuId ? (
                                breacrumb
                            ) : (
                                <span>
                                    <i className="fa fa-long-arrow-left mr-2"></i>Select a Category
                                </span>
                            )}
                        </li>
                    </ol>
                </nav>

                {/*Table*/}
                {selMenuId && (
                    <CRUDIndex
                        tableData={tblData}
                        tableConfig={selMenuContent}
                        onUpdateRow={this.onUpdateRow}
                        onDeleteRow={this.onDeleteRow}
                    />
                )}
            </div>
        );
    }
}
