import React, { Component } from "react";
import UsersList from "./list";
import UserDetails from "./userDetails";

class UsersIndex extends Component {

  constructor() {
    super();
    this.state = {
      selectedUser: null
    };
    this.onSelectRow = this.onSelectRow.bind(this);
    this.onGoBack = this.onGoBack.bind(this);
  }

  onSelectRow(id) {
    this.setState({ selectedUser: id });
  }

  onGoBack() {
    this.setState({ selectedUser: null });
  }

  render() {
    const { selectedUser } = this.state;
    return (
      <div>
        {selectedUser ? (
          <UserDetails userId={selectedUser} onGoBack={this.onGoBack} />
        ) : (
          <UsersList onSelectRow={this.onSelectRow} />
        )}
      </div>
    );
  }

}

export default UsersIndex;
