import React, { Component } from "react";
import Button from "../form/Button";
import config from "data/config";

export default class RazorPayCheckout extends Component {
    constructor(props) {
        super(props); 
        this.paymentHandler = this.paymentHandler.bind(this);
    }

    componentDidMount() {
        const script = document.createElement("script");

        script.src = "https://checkout.razorpay.com/v1/checkout.js";
        script.async = true;

        document.body.appendChild(script);
    }

    paymentHandler(e) {
        e.preventDefault();

        const {amount} = this.props;
        if(!amount) return this.props.onComplete({razorpay_payment_id: null})

        const {brandName, brandLogo, productDescription, name, email, phone: contact} = this.props;

        let options = {
            key: config.RAZORPAY_ID,
            amount, 
            name: brandName,
            description: productDescription || "Appointment Confirmation",
            image: brandLogo,
            handler: res => {
                this.props.onComplete(res);
            },
            prefill: { name, email, contact},
            notes: {
                address: "Hello World",
            },
            theme: { color: "#47b0df",},
        };

        let rzp = new window.Razorpay(options);
        rzp.open();
    }

    render() {
        const {actionTxt} = this.props;
        return (
            <div>
                <Button
                            btnCls="btn-primary theme-btn"
                            iconCls="fa-credit-card"
                            value={actionTxt || "Pay Now"}
                            onClick={this.paymentHandler}
                        />
            </div>
        );
    }
}
