import React, { Component } from "react";
import SectionHead from "pages/app/components/sectionHead";
import Auth, { AuthModes } from "pages/components/auth";
import Button from "pages/components/form/Button";

export default class ChangePass extends Component {
  render() {
    return (
      <div>
        <Button btnCls="btn-outline-secondary" iconCls="fa-long-arrow-left" value="Go Back" onClick={this.props.onGoBack} />
        <div className="col-6 mx-auto pt-4">
          <Auth mode={AuthModes.changePW} showLoginLinkInChangePW={false} />
        </div>
      </div>
    );
  }
}
