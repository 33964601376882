import { getUserId, getVendorId, getSubRole } from "utils";
import { gqlSend } from "utils/AxiosUtil";
import { A4P_ROLE_USER, A4P_VENDOR_TYPE } from "data/config/variables";

export const searchVendorListings = () => {
    const vendorId = getVendorId();
    const userID = getUserId();
    const subRole = getSubRole();

    const _w = `vendor_id: {_eq: ${vendorId}} 
                ${subRole === A4P_VENDOR_TYPE.USER ? `, managers: {_contains: [${userID}]}` : ""}`;

    const q = {
        query: `
        {
          vendor_listings(where: {${_w}} ) {
            id, avatar, title, line1, line2, address, vendor_login_id, managers, timezone, consultations, created_at, updated_at
          }
        }`
    };

    return gqlSend(q);
};

export const createOrUpdateListing = params => {
    const vendor_id = getVendorId();
    const { id, avatar, title, line1, line2, address, vendor_login_id, managers, timezone, consultations } = params;

    const _s =
        "avatar: $avatar, title: $title, line1: $line1, line2: $line2, address: $address, timezone: $timezone, managers: $managers, " +
        "consultations: $consultations, vendor_id: $vendor_id, vendor_login_id: $vendor_login_id ";

    const st = params.id
        ? `update_vendor_listings ( where: {id: {_eq: $id}}, _set: {${_s}} ) {
      affected_rows
    }`
        : `insert_vendor_listings (objects: {${_s}}) {
          returning {id}
    }`;

    const q = {
        query: ` 
    mutation ($id: Int, $avatar: String, $title: String, $line1: String, $line2: String, $address: String, $timezone: String, 
      $managers: jsonb, $consultations: jsonb, $vendor_id: Int, $vendor_login_id: Int ) {
      ${st}
    }`,
        variables: {
            id,
            avatar,
            title,
            line1,
            line2,
            address,
            timezone,
            managers,
            consultations,
            vendor_id,
            vendor_login_id
        }
    };

    return gqlSend(q);
};
