import React, { Component } from "react";
import { APP_EXOTEL_NUMBER } from "data/config/variables";

export default class RTCTeleConsult extends Component {
    render() {
        const number = APP_EXOTEL_NUMBER;
        const {bookingId, isVendor} = this.props;

        return (
            <div className="my-3 mx-4 text-center">
                The doctor will call you at the appointment time
            </div>
        );
    }
}
