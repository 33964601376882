
import bcrypt from 'bcryptjs';
import { gqlSend, apiSend } from "utils/AxiosUtil";

export const signup = (state) => {

  const encryptedPassword = bcrypt.hashSync(state.password, bcrypt.genSaltSync(10));

  const q = {
    query: `mutation( $vn : String, $fn: String, $ln:String, $eml: String, $phone: String, $pw: String, $type: Int, $vlogin: jsonb, $categories: jsonb, $details: jsonb)
        {
          insert_vendors(objects:
          {
            name: $vn,
            vendor_logins:
            {
              data: {
                first_name: $fn,
                last_name: $ln,
                phone: $phone,
                email: $eml,
                password: $pw
              }                  
            },
            categories: $categories,
            type: $type,
            details: $details
          })
          {
            affected_rows
          }
        }`,
    variables: {
      vn: state.first_name + " " + state.last_name,
      fn: state.first_name,
      ln: state.last_name,
      phone: state.mobile_phone,
      eml: state.email,
      type: state.type,
      pw: encryptedPassword,
      details: {
        mobile: state.mobile_phone,
        email: state.email,
        summary: state.summary
      }
    }
  };

  return gqlSend(q);
}

export const login = (state) => {
  const data = {
    email: state.email,
    password: state.password,
    role: state.role
  };
  return apiSend(data, "/api/account/login");
}