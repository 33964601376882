import bcrypt from "bcryptjs";
import { gqlSend, apiSend } from "utils/AxiosUtil";
import { getUserEmail, getUserRole } from "utils"; 

// signup user
export const signup = state => {
	const data = {
		first_name: state.first_name,
		last_name: state.last_name,
		email: state.email.trim().toLowerCase(),
		phone: state.phone,
		password: state.password,
		plan_id: state.planId,
		source: state.source,
		role: state.role
	};
	return apiSend(data, "/api/account/register");
};

export const login = state => {
	const data = {
		email: state.email.trim().toLowerCase(),
		password: state.password,
		role: state.role
	};
	return apiSend(data, "/api/account/login");
};

export const forgotPW = state => {
	const qry = {
		query: `mutation($eml: String, $role: String)
        {
          insert_forgot_password(objects: {email: $eml, role: $role})
          { affected_rows }
        }`,
		variables: {
			eml: state.email.trim().toLowerCase(),
			role: state.role
		}
	};

	return gqlSend(qry);
};

export const sendOTP = mobile => {
	const qry = {
		query: `mutation($mobile: String)
        {
          insert_forgot_password(objects: {mobile: $mobile})
          { affected_rows }
        }`,
		variables: {
			mobile
		}
	};

	return gqlSend(qry);
};

// verify OTP
export const verifyOTP = (mobile, otp) => {
	return apiSend({mobile, otp}, "/api/account/verify_otp");
};

export const changePW = state => {
	const encryptedPassword = bcrypt.hashSync(
		state.newPassword,
		bcrypt.genSaltSync(10)
	);

	const data = {
		forgotCode: state.forgotCode,
		currentPassword: state.currentPassword,
		newPassword: encryptedPassword,
		email: getUserEmail(),
		role: getUserRole()
	};

	return apiSend(data, "/api/account/change_password");
};

export const fetchCoupon = coupon => {
	return gqlSend({
		query: `{
      plans(where: {code: {_ilike: "${coupon}"}}) {
        id, code, description, details, offers
      }
    }`
	});
};
