import React, { Component } from "react";
import { Link } from "react-router-dom";
import { logoutUser } from "utils/LoginChecker";
import { Helmet } from "react-helmet";

import "bootstrap/dist/css/bootstrap.min.css";
import "./css/style.css";
import "./css/external.css";
import { getUser, getSubDomain } from "utils";

class PublicHeader extends Component {
    constructor(params) {
        super(params);
        const user = getUser();
        this.state = {
            changeBackground: false,
            userPic: user.pic || "/img/profile.svg",
            subDomain: getSubDomain()
        };
    }

    listenScrollEvent = e => {
        if (window.scrollY > 100) {
            this.setState({ changeBackground: true });
        } else {
            this.setState({ changeBackground: false });
        }
    };

    componentDidMount() {
        window.addEventListener("scroll", this.listenScrollEvent);
    }

    render() {
        const { subDomain } = this.state;

        let headerCls = "site-header fixed-top bg-white navbar-light ";

        headerCls += this.props.showTransparentBG
            ? this.state.changeBackground
                ? "whiteHeader"
                : "tranHeader"
            : " whiteHeader";

        if(subDomain) headerCls="";

        return (
            <header
                id="masthead"
                className={headerCls}
                role="banner"
                style={this.state}
            >
                {subDomain && (
                    <Helmet>
                        <title>{subDomain.name} - TeleConsulation</title>
                    </Helmet>
                )}
                <div className="container">
                    <nav className="navbar navbar-expand-md my-2 p-0">
                        <div className="navbar-brand ">
                            {subDomain ? (
                                <Link to="/">
                                    {subDomain.logo ? (
                                        <img
                                            src={subDomain.logo}
                                            className=""
                                            alt={subDomain.name}
                                            style={{ width: "320px" }}
                                        />
                                    ) : (
                                        <div className="h5">
                                            {subDomain.name}
                                        </div>
                                    )}
                                </Link>
                            ) : (
                                <Link to="/">
                                    <img
                                        src="/img/ado-logo.png"
                                        alt="ActivDoctors"
                                    />
                                </Link>
                            )}
                        </div>
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#main-nav"
                            aria-controls
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div
                            id="main-nav"
                            className="collapse navbar-collapse justify-content-end"
                        >
                            <ul id="menu-main-menu" className="navbar-nav">
                                {!subDomain && (
                                    <li className="nav-item dropdown">
                                        <a
                                            className="nav-link dropdown-toggle"
                                            href="#!"
                                            role="button"
                                            id="dropdownMenuLink"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            Services &nbsp;&nbsp;
                                        </a>

                                        <div
                                            className="mt-2 dropdown-menu"
                                            aria-labelledby="dropdownMenuLink"
                                        >
                                            <Link
                                                className="dropdown-item"
                                                to="/vet-appointments/"
                                            >
                                                <i className="material-icons">
                                                    meeting_room
                                                </i>{" "}
                                                Vet Appointments
                                            </Link>
                                            <div className="dropdown-divider" />
                                            <Link
                                                className="dropdown-item"
                                                to="/vet-house-call/"
                                            >
                                                <i className="material-icons">
                                                    store_mall_directory
                                                </i>{" "}
                                                House Visits
                                            </Link>
                                            <div className="dropdown-divider" />
                                            <Link
                                                className="dropdown-item"
                                                to="/e-consultation/"
                                            >
                                                <i className="material-icons">
                                                    voice_chat
                                                </i>{" "}
                                                E-Consultation
                                            </Link>
                                            <div className="dropdown-divider" />
                                            <Link
                                                className="dropdown-item"
                                                to="/talk-to-a-vet/"
                                            >
                                                <i className="material-icons">
                                                    local_phone
                                                </i>{" "}
                                                Talk To A Vet
                                            </Link>
                                            <div className="dropdown-divider" />
                                            <Link
                                                className="dropdown-item"
                                                to="/second-medical-opinion/"
                                            >
                                                <i className="material-icons">
                                                    call_split
                                                </i>{" "}
                                                Second Medical Opinion
                                            </Link>
                                            <div className="dropdown-divider" />
                                            <Link
                                                className="dropdown-item"
                                                to="/pet-medical-records/"
                                            >
                                                <i className="material-icons">
                                                    pets
                                                </i>{" "}
                                                Medical Records
                                            </Link>
                                        </div>
                                    </li>
                                )}
                                {!subDomain && (
                                    <li
                                        id="menu-item-206"
                                        className="nav-item menu-item menu-item-type-post_type menu-item-object-page menu-item-206"
                                    >
                                        <Link
                                            title="For Vets"
                                            to="/for-vet/"
                                            className="nav-link"
                                        >
                                            For Vets
                                        </Link>
                                    </li>
                                )}
                                {!this.props.loggedIn ? (
                                    <li
                                        id="menu-item-29"
                                        className="nav-item menu-item menu-item-type-post_type menu-item-object-page menu-item-29"
                                    >
                                        <Link
                                            title="Login"
                                            to="/login/"
                                            className="nav-link"
                                        >
                                            Login
                                        </Link>
                                    </li>
                                ) : (
                                    <li className="nav-item ">
                                        <a
                                            className="nav-link drodown-toggle"
                                            href="#!"
                                            role="button"
                                            id="dropdownMenuLink"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            style={{ marginTop: "-5px" }}
                                        >
                                            <img
                                                style={{
                                                    width: "32px",
                                                    borderRadius: "50%"
                                                }}
                                                className="img-responsive"
                                                src={this.state.userPic}
                                            />
                                        </a>
                                        <div className="dropdown-menu dropdown-menu-right ">
                                            <Link
                                                className="dropdown-item"
                                                to="/app"
                                            >
                                                <i className="material-icons">
                                                    dashboard
                                                </i>{" "}
                                                Dashboard
                                            </Link>
                                            <div className="dropdown-divider" />
                                            <Link
                                                className="dropdown-item"
                                                to="#"
                                                onClick={() => logoutUser()}
                                            >
                                                <i className="material-icons">
                                                    power
                                                </i>{" "}
                                                Logout
                                            </Link>
                                        </div>
                                    </li>
                                )}
                                {/* to avoid backgroud on last li - inserting dummy li*/}
                                {this.props.loggedIn && <li />}
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>
        );
    }
}

export default PublicHeader;
