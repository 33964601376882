import { RA_LOGIN, RA_SET_CURRENT_USER, RA_UPDATE_USER_PROFILE } from "./types";

// Login User
export const loginUser = userData => {
  return{
    type: RA_LOGIN,
    payload: userData
  }
}

// Set logged in user
export const setCurrentUser = decoded => {
  return {
    type: RA_SET_CURRENT_USER,
    payload: decoded
  };
};

export const updateUserInfo = decoded => {
  return {
    type: RA_UPDATE_USER_PROFILE,
    payload: decoded
  };
};

