import React, { Component } from "react";
import PropTypes from "prop-types";

import SimpleReactValidator from "simple-react-validator";
import FormElement from "pages/components/form/FormElement";
import { login } from "./network";
import { A4P_ROLE_USER } from "data/config/variables";

class Login extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.validator = new SimpleReactValidator({
            // language customization
            /*  messages: {
        required: 'Пакажыце сапраўдны кантактны нумар',
        email: 'That is not an email.',
        default: 'koollll '
      }  */
        });
        this.state = {
            email: "",
            password: "",
            role: this.props.role,
            submitBtnTxt: "Submit"
        };
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value.trim() });
    }

    onFormSubmit(e) {
        e.preventDefault();

        if (this.validator.allValid()) {
            this.setState({
                submitBtnTxt: "Please Wait...",
                serverErrors: false,
                serverErrorMessage: ""
            });

            login(this.state)
                .then(r => {
                    console.log(r);
                    this.props.onLoginComplete(r);
                })
                .catch(err => {
                    console.log(err);
                    this.setState({
                        submitBtnTxt: "Submit",
                        serverErrors: true,
                        serverErrorMessage: "Invalid email or password."
                    });
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate(); // [validator] rerender to show messages for the first time
        }
    }

    render() {
        const warningBlock = () => (
            <div className="alert alert-danger">
                <strong className="mr-2">Error!</strong> {this.state.serverErrorMessage}
            </div>
        );

        return (
            <div>
                {this.state.serverErrors && warningBlock()}
                <form id="vlogin" method="post" onSubmit={this.onFormSubmit}>
                    <div className="row">
                        <FormElement
                            name="email"
                            className="col-md-12"
                            placeHolder="Email"
                            state={this.state}
                            onChange={this.onChange}
                            validator={this.validator}
                            validation="required|email"
                        />

                        <FormElement
                            name="password"
                            type="password"
                            className="col-md-12"
                            placeHolder="Password"
                            state={this.state}
                            onChange={this.onChange}
                            validator={this.validator}
                            validation="required"
                        />
                    </div>

                    <div className="w-100 my-3">
                        <button type="submit" className="btn btn-primary btn-block" value="Submit">
                            {this.state.submitBtnTxt}
                        </button>
                    </div>
                </form>
            </div>
        );
    }
}

Login.defaultProps = {
    role: A4P_ROLE_USER
};

Login.propTypes = {
    onLoginComplete: PropTypes.func.isRequired,
    role: PropTypes.string
};

export default Login;
