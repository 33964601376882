import React, { Component } from "react";
//import FacebookLogin from "react-facebook-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import GoogleLogin from "react-google-login";

export default class SocialLogin extends Component {
  render() {
      let actionTxt = this.props.action || "Login";
    const responseFacebook = response => {
      console.log(response);
    };
    const responseGoogle = response => {
      console.log(response);
    };
    return (
      <div>
        <GoogleLogin
          clientId="525889392077-1ua7pr2lda09lpghsc6vmf5sml0j7pbl.apps.googleusercontent.com"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          render={renderProps => (
            <button
              className="btn btn-block btn-google"
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
            >
              <i className="fa fa-google mr-2" />
              {`${actionTxt} via Google`}
            </button>
          )}
        />

        <FacebookLogin
          appId="952532454916509"
          fields="name,email,picture"
          callback={responseFacebook}
          render={renderProps => (
            <button
              className="btn btn-block btn-facebook my-3"
              onClick={renderProps.onClick}
            >
              <i className="fa fa-facebook mr-2" />
              {`${actionTxt} via Facebook`}
            </button>
          )}
        />
      </div>
    );
  }
}
