
import { gqlSend } from "utils/AxiosUtil";

export const fetchBookingDetails = (userId, bookingId) => {
  const q = {
    query: `
        {
            bookings(where: {id: {_eq: ${bookingId}}, user_id: {_eq: ${userId}}}) {
               id, slot, user_id, vendor_id, details, payment_id, status
            }
        }`
  };
  return gqlSend(q);
}