import React, { Component } from "react";
import { getA4PService } from "utils";

export default class ListingItem extends Component {
  constructor(props) {
    super(props); 
    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    e.preventDefault();
    this.props.onSelect({ id: this.props.data.id });
  }

  render() {
    const { title, line1, line2, address } = this.props.data;
    return (
      <div className="appBox">
        <div className="d-flex">
          <div>
            <a href="#!" onClick={this.onClick}>
              <i
                className="material-icons text-secondary"
                style={{ fontSize: "5rem", padding: "1rem" }}
              >
                work
              </i>
            </a>
          </div>
          <div className="ml-2">
            <p className="lead mt-2 text-secondary" onClick={this.onClick}>{title} </p>
            <p className="text-secondary">{line1}</p>
           {line2 && <p className="text-secondary">{line2}</p>}
           {address && <p className="text-secondary">{address}</p>} 
          </div>
          <div className="ml-auto align-self-end">
            <a href="#!" className="btn btn-light mb-1" onClick={this.onClick}>
              Manage Listing
            </a>
          </div>
        </div>
      </div>
    );
  }
}
