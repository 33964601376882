import { gqlSend } from "utils/AxiosUtil";
import { A4P_ROLE_USER } from "data/config/variables";

export const search = state => {
    const q = {
        query: `query( $sid: Int, $offset: Int, $limit: Int )
        {
          appointments(where: {service_id: {_eq: $sid}}, order_by: {created_at: desc}, offset: $offset, limit: $limit) {
            details, for_ts, created_at, from_ts, id,  service_id
          }
        }`,
        variables: {
            sid: state.sid,
            offset: state.offset,
            limit: state.limit
        }
    };

    return gqlSend(q);
};

export const searchListings = state => {
    const q = {
        query: `query( $offset: Int, $limit: Int )
        {
          vendor_services ( offset: $offset, limit: $limit) {
            address, categories, city, country, created_at, description, details, id, name, education, avatar, specialization, 
            pets, pics, price, roster, state, status, type, vendor_id, zipcode, provider_name, notification_email
          }
        }`,
        variables: {
            offset: state.offset,
            limit: state.limit
        }
    };

    return gqlSend(q);
};

export const searchVetAppointments = state => {
    const q = {
        query: `query( $offset: Int, $limit: Int )
        {
          appointments(order_by: {created_at: desc}, offset: $offset, limit: $limit) {
            details, for_ts, created_at, from_ts, id,  service_id, 
            user { address, last_name, first_name, pic }
          }
        }`,
        variables: {
            offset: state.offset,
            limit: state.limit
        }
    };

    return gqlSend(q);
};

export const updateUserProfile = state => {
    const tblName = state.role === A4P_ROLE_USER ? "update_users" : "update_vendor_logins";
    return gqlSend({
        query: `mutation( $id: Int, $fn: String, $ln:String, $pic: String)
      {
        ${tblName}(where: {id: {_eq: $id}}, _set: {first_name: $fn, last_name: $ln, pic: $pic}) {
          affected_rows
        }
      }`,
        variables: { id: state.id, fn: state.first_name, ln: state.last_name, pic: state.pic }
    });
};

export const getUserIdFromEmail = email => {
    const q = {
        query: `
        {
            users(where: {email: {_eq: "${email}"}}) {
                id
              }
        }`
    };
    return gqlSend(q);
};
