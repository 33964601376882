import { PUBLIC_GET_DETAILS } from "../../actions/types";

const initialState = {
  timeslots: [],
  user: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
      case PUBLIC_GET_DETAILS: 
          return {
              ...state,
              timeslots: action.payload.timeslots
          }
      default:
          return state;
  }
}