import { RA_SET_CURRENT_USER, RA_UPDATE_USER_PROFILE } from "../actions/types";
import isEmpty from "../../utils/isEmpty";

const initialState = {
	isAuthenticated: false,
	user: {}
};

export default function(state = initialState, action) {
	switch (action.type) {
		case RA_SET_CURRENT_USER:
			return {
				...state,
				isAuthenticated: !isEmpty(action.payload),
				user: action.payload.user
			};
		case RA_UPDATE_USER_PROFILE:
			return {
				...state,
				user: {...state.user, ...action.payload}
			};
		default:
			return state;
	}
}
