import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers";
import config from "data/config";

const initialState = {};

const middleware = [thunk];

const store = createStore(
  rootReducer,
  initialState,
  config.ENABLE_REDUX_CHROME_EXT
    ? compose(
        // required for enabling chrome redux extension
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
      )
    : compose(
        // required for enabling chrome redux extension
        applyMiddleware(...middleware)
      )
);

export default store;
