import React, { Component } from "react";
import { A4P_APPOINTMENT_STATUSES, A4P_APPOINTMENT_STATUS_SELECT, A4P_ROLE_USER } from "data/config/variables";
import { searchVendorListings } from "pages/app/vendor/listing/network";
import { TIMEZONES } from "pages/components/roster/constants";
import FormElement from "pages/components/form/FormElement";
import Select from "react-select";
import isEmpty from "utils/isEmpty";
import moment from "moment-timezone";
import { getUserRole } from "utils";

export default class AppointmentsFilters extends Component {
    constructor(props) {
        super(props);

        this.allSortOptions = [
            { value: "slot", label: "Appointment Date" },
            { value: "created_at", label: "Booking Date" }
        ];

        this.state = {
            role: getUserRole(),
            allListings: null,
            listing: null,
            status: A4P_APPOINTMENT_STATUSES.IN_PROGRESS,
            sortBy: this.allSortOptions[0].value,
            timezone: moment.tz.guess()
        };

        this.fetchData();
        this.onUpdate = this.onUpdate.bind(this);
    }

    fetchData() {
        const { role } = this.state;
        if (role !== A4P_ROLE_USER) {
            searchVendorListings()
                .then(r => {
                    const li = r.data.vendor_listings;
                    if (!isEmpty(li)) {
                        const allListings = li.map(l => {
                            return { value: l.id, label: l.title };
                        });
                        this.setState({ allListings });
                    }
                })
                .catch(r => console.log(r));
        }
    }

    componentDidMount() {
        this.onUpdate();
    }

    onUpdate() {
        const { listing, status, timezone, sortBy } = this.state;
        this.props.onUpdate({ listing, status, timezone, sortBy });
    }

    render() {
        const { allSortOptions } = this;
        const { role, allListings, listing, status, sortBy, timezone } = this.state;

        return (
            <div className="d-flex flex-column bg-light p-3 ">
                <div className="pb-2 mb-3 border-bottom lead text-muted">
                    Filters:
                </div>
                <FormElement>
                    <div title="Select users who can manage this listing and appointments">
                        <label className="text-muted">Status: </label>
                        <Select
                            options={A4P_APPOINTMENT_STATUS_SELECT}
                            isClearable={true}
                            placeholder="Select Status"
                            value={A4P_APPOINTMENT_STATUS_SELECT.filter(op => op.value === status)}
                            onChange={sel => this.setState({ status: sel ? sel.value : null }, this.onUpdate)}
                        />
                    </div>
                </FormElement>

                {role !== A4P_ROLE_USER && (
                    <FormElement>
                        <div title="Select users who can manage this listing and appointments">
                            <label className="text-muted">Listing: </label>
                            <Select
                                options={allListings}
                                isClearable={true}
                                placeholder="Select Listing"
                                value={allListings && allListings.filter(op => op.value === listing)}
                                onChange={sel => this.setState({ listing: sel ? sel.value : null }, this.onUpdate)}
                            />
                        </div>
                    </FormElement>
                )}

                <FormElement>
                    <div title="Select users who can manage this listing and appointments">
                        <label className="text-muted">Sort By: </label>
                        <Select
                            options={allSortOptions}
                            placeholder="Select Status"
                            value={allSortOptions.filter(op => op.value === sortBy)}
                            onChange={sel => this.setState({ sortBy: sel ? sel.value : null }, this.onUpdate)}
                        />
                    </div>
                </FormElement>

                <FormElement className=" ">
                    <div title="Select the timezone of your clinic">
                        <label className="text-muted">Timezone: </label>
                        <Select
                            name="timezone"
                            options={TIMEZONES}
                            value={TIMEZONES.map(op => (timezone.includes(op.value) ? op : false))}
                            onChange={sel => this.setState({ timezone: sel ? sel.value : null }, this.onUpdate)}
                        />
                    </div>
                </FormElement>
            </div>
        );
    }
}
