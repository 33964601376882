import { getUserId } from "utils";
import { gqlSend, apiSend } from "utils/AxiosUtil";

export const getIHMRecord = () => {
	return gqlSend({
		query: `{ihm(where: {user_id: {_eq: ${getUserId()}}}) { 
			basic_report, status, final_report,booking_id}
		}`,
	});
};

// inset or update IHM questions and basic report
export const sendIHMAnswersAndReport = ({ answers, basic_report }) => {
	const qry = {
		query: `mutation($user_id: Int, $answers: jsonb, $basic_report: jsonb)
        {
            insert_ihm(objects: {answers: $answers, basic_report: $basic_report, user_id: $user_id}, on_conflict: {constraint: ihm_user_id_key, update_columns: [answers, basic_report]})
            { affected_rows }
        }`,
		variables: {
			user_id: getUserId(),
			answers,
			basic_report,
		},
	};

	return gqlSend(qry);
};

// todo replace razorpay id with payment id
export const updateIHMPaymentStatus = (payId, status) => {
	const qry = {
		query: `mutation
        {
            update_ihm(where: {user_id: {_eq: ${getUserId()}}}, _set: {status: ${status}, razorpay_id : "${payId}"}) {
				affected_rows
			  }
        }`,
	};
	return gqlSend(qry);
};

export const updateIHMBookingStatus = (bookingId, files, status) => {
	const qry = {
		query: `mutation($files: jsonb)
        {
            update_ihm(where: {user_id: {_eq: ${getUserId()}}}, _set: {files: $files, status: ${status}, booking_id : "${bookingId}"}) {
				affected_rows
			  }
        }`,
		variables: {
			files,
		},
	};

	return gqlSend(qry);
};
