import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class NotFound extends Component {
  render() {
    return (
      <div className="container">
        <div
          className="row d-flex align-items-center justify-content-center py-5"
          style={{ margin: "10%" }}
        >
          <div >
            <p className="display-2">404</p>
            <p className="lead ">Oops! Sorry page not found</p>
            <div className="mt-4">
              <Link title="Home" to="/" className="btn btn-outline-primary">
                Go Home
              </Link>
            </div>
          </div>
          <div className="offset-1 col-7">
            <img src="/img/404.jpg" alt="Page Not Found" />
          </div>
        </div>
      </div>
    );
  }
}
