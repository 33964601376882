import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { setupAuth, updateSubDomainInStore } from "utils/LoginChecker";
import PublicRoute from "pages/public";
import UserRoute from "pages/app";
import RTCRoute from "pages/rtc";
import IHMRoute from "pages/ihm";
import config from "data/config";
import { getDomainName } from "utils";
import { getVendorSite } from "pages/public/network";
import PHRView from "pages/phr";

// check for login
setupAuth();

// disable console.log for production
if (config.ENV === "production")
	console.log = console.warn = console.error = () => {};

const IHM_HOSTS = [
	"consult.activdoctorsonline.com",
	"ihm.activ-doctors.com",
	"consult.localhost:3000",
];
class App extends Component {
	constructor(props) {
		super(props);

		// detect subdomain and customdomain
		let customDomain = window.location.host;
		let subDomain = null;

		if (customDomain.includes(getDomainName(config.SITE_URL))) {
			const parts = customDomain.split(".");
			const [sub] = parts;
			// subdomain and length is greater than 3
			subDomain = parts.length === 3 && sub.length > 3 ? sub : null;
			customDomain = null;
		}
		
		if (IHM_HOSTS.includes(window.location.host)) {
			customDomain = null;
			subDomain = null;
		}

		this.state = {
			subDomain,
			customDomain,
			loadingSubdomain: Boolean(customDomain || subDomain),
			siteNotFound: false,
		};

		if (this.state.loadingSubdomain) {
			getVendorSite({ subDomain, customDomain })
				.then((r) => {
					const [sd] = r.data.websites;
					if (sd) {
						updateSubDomainInStore(sd);
						this.setState({ loadingSubdomain: false });
					} else
						this.setState({
							loadingSubdomain: false,
							siteNotFound: true,
						});
				})
				.catch((err) => console.log(err));
		}
	}

	render() {
		const { loadingSubdomain, siteNotFound } = this.state;

		return (
			<div>
				{loadingSubdomain ? (
					<h6 className="p-2 text-muted">
						<i className="fa fa-spinner fa-spin mr-1"></i>{" "}
						loading...
					</h6>
				) : siteNotFound ? (
					<h6 className="p-2 text-muted">Site not found</h6>
				) : (
					<Router>
						<Switch>
							{/* IHM Route on the root url */}
							{/* <Route path="/ihm" component={IHMRoute} /> */}
                            <Route
                                path="/" 
                                exact
								render={(props) => {
									//const [sub] = window.location.hostname.split(".");
									//if (sub === IHM_SUBDOMAIN)
									if (IHM_HOSTS.includes(window.location.host))
										return <IHMRoute {...props} />;
									return <PublicRoute {...props} />;
								}}
							/>

							{/* WebRTC Route */}
							<Route path="/appointments" component={RTCRoute} />

							{/* User Routes */}
							<Route path="/app" component={UserRoute} />

							{/* PHR Routes */}
							<Route path="/3*" component={PHRView} />

							{/* Public Routes */}
							<Route path="/" component={PublicRoute} />

						</Switch>
					</Router>
				)}
			</div>
		);
	}
}

function App1() {
	return (
		<Router>
			<Switch>
				{/* WebRTC Route */}
				<Route path="/appointments" component={RTCRoute} />

				{/* User Routes */}
				<Route path="/app" component={UserRoute} />

				{/* Public Routes */}
				<Route path="/" component={PublicRoute} />
			</Switch>
		</Router>
	);
}

export default App;
