export const labPrescriptionGenerator = (form) => {
	const tests = ["Blood Pressure Test", "HbA1c Test", "Cholesterol Test"];

	const sex = form["g-sex"];
	const age = form["g-age"];
	const race = form["g-race"];

	const BMI = form["g-wt"] / ((form["g-ht"] * form["g-ht"]) / 10.64);

	const q7 = form["l-diabp"];
	const q8 = form["l-heartp"];

	const q10 = form["m-colo"];
	const q10a = form["m-colo-a"];
	const q10b = form["m-colo-b"];
	const q10c = form["m-colo-c"];
	const q10d = form["m-colo-d"];

	// for prostrate Cancer
	if ((sex === "male" && age > 50) || (age > 40 && race === "black"))
		tests.push("PSA Blood Test");

	// Lung Cancer
	if (
		(age > 55 && form["l-cigd"] > 1 && form["l-cigy"] > 30) ||
		form["l-asb"] === "yes"
	)
		tests.push("CT Lung Scan Test");

	// Colon cancer
	if (
		(age > 45 && q10 === "yes" && q10b > 3 && q10b === "yes") ||
		(age > 45 && q10 === "yes" && q10a > 7) ||
		(age > 45 && q10 === "no") ||
		(q10 === "no" && q10c === "yes" && q10d - age < 10)
	)
        tests.push("Colonoscopy/Cologuard Test");
        
/* 
	// Diabetes
	if (
		(age > 50 && BMI > 30) ||
		(age > 40 && BMI > 35) ||
		(age > 50 && q7 === "yes")
	)
		tests.push("Hemoglobin A1C blood test");

	// High BP
	if (
		(form["g-pre-diab"] =
			"yes" || form["g-sbp"] > 140 || form["g-dbp"] > 90)
	)
		tests.push("Blood Pressure Test");

	// High Cholesterol
	if (age > 40 || (age > 18 && q8 === "yes"))
		tests.push("Fasting Lipid Panel Blood Test");
 */
	return tests;
};
