import jwt_decode from "jwt-decode";
import store from "../data/store";
import { setCurrentUser } from "../data/actions/authActions";
import { setAuthToken } from "./AxiosUtil";
import { updateSubDomainInfo } from "data/actions/configActions";

// logs in a user
export const loginUser = (jwt_token, redirectUrl) => {
  if (jwt_token) {

    // save in localstorage
    localStorage.setItem("jwtToken", jwt_token);

    // setup info in redux
    setupAuth();

    // Redirect to home/login
    if(redirectUrl) window.location.href = redirectUrl; 
  }
};

// sets up login info if jwt token present and valid
export const setupAuth = () => {
  
  if (localStorage.jwtToken) {
    // Set auth token header auth
    setAuthToken(localStorage.jwtToken);

    // Decode token and get user info and exp
    const decoded = jwt_decode(localStorage.jwtToken);

    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded));

    // Check for expired token
    const currentTime = Math.round(Date.now() / 1000);
    if (decoded.exp < currentTime) logoutUser();
  }
};

// logs out the user, clears all data set
export const logoutUser = () => {
  // Remove token from localStorage
  localStorage.removeItem("jwtToken");

  // Remove auth header for future requests
  setAuthToken(false);

  // Logout user - Set current user to {} which will set isAuthenticated to false
  store.dispatch(setCurrentUser({}));

  // Redirect to home/login
  window.location.href = "/"; // todo setup url from config
};

// Just remove the token which forces the user to login upon refresh
export const removeLoginToken = () => {
  // Remove token from localStorage
  localStorage.removeItem("jwtToken");
}

// setup subdomain info
export const updateSubDomainInStore = data => {
  store.dispatch(updateSubDomainInfo(data));
}
