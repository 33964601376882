import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw, ContentState, convertFromHTML } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

export default class MyEditor extends Component {
    constructor(props) {
        super(props);
        /* let editorState = EditorState.createEmpty();

        const html = this.props.content;
        const contentBlock = convertFromHTML(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap);
            editorState = EditorState.createWithContent(contentState);
        } */

        this.state = { editorState: EditorState.createEmpty() };
    }

    componentDidUpdate(prevProps) {
        const { content } = this.props;
        if (prevProps.content !== content) {
            const contentBlock = convertFromHTML(content);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(
                    contentBlock.contentBlocks,
                    contentBlock.entityMap
                );
                this.setState({ editorState: EditorState.createWithContent(contentState) });
            }
        }
    }

    onEditorStateChange = editorState => {
        this.setState({ editorState });
        if (this.props.onContentChange) {
            this.props.onContentChange(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        }
    };

    render() {
        const { editorState } = this.state;
        return (
            <Editor
                editorState={editorState}
                wrapperClassName="border"
                editorClassName="px-3"
                editorStyle={{ minHeight: "12em" }}
                onEditorStateChange={this.onEditorStateChange}
            />
        );
    }
}
