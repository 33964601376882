import axios from "axios";
import config from "data/config";
import parseGQLError from "utils/HasuraParser";

export const setAuthToken = token => {
  if (token) {
    // Apply to every request
    axios.defaults.headers.common["Authorization"] = token;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common["Authorization"];
  }
};

// graph QL call
export const gqlSend = q => {
  return axios
    .post(config.GQL_ENDPOINT, JSON.stringify(q))
    .then(res => { console.log(res)
      if (res.data && res.data.errors) throw parseGQLError(res.data.errors);
      return res.data;
    })
    .catch(err => {
      throw typeof err === "object" ? parseGQLError(null) : err;
    });
};

// api call 
export const apiSend = (data, endpoint) => {
  return axios
    .post(config.API_ENDPOINT + endpoint, data)
    .then(res => res.data)
    .catch(error => {
      throw error;
    });
};
