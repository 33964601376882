import React, { Component } from "react";
import FormElement from "pages/components/form/FormElement";
import SimpleReactValidator from "simple-react-validator";
import { setDomainDetails, getDomainDetails } from "./network";
import Button from "pages/components/form/Button";
import { GQL_ERROR_DUPLICATE } from "utils/HasuraParser";
import { getDomainName } from "utils";
import config from "data/config";

export default class SettingsDomainsIndex extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.validator = new SimpleReactValidator({});

    this.state = {
      recordId: null,
      custom_domain: null,
      sub_domain: null,
      updating: false,
      success: false,
      error: null
    };
  }

  componentDidMount() {
    getDomainDetails()
      .then(r => {
        const [site] = r.data.websites;
        const { id, sub_domain, custom_domain } = site || {};
        this.setState({ recordId: id, sub_domain, custom_domain });
      })
      .catch(r => console.log(r));
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value.trim() });
  }

  onFormSubmit() {
    if (this.validator.allValid()) {
      const { recordId, sub_domain, custom_domain } = this.state;
      this.setState({ updating: true, success: false });
      setDomainDetails(
        recordId,
        getDomainName(sub_domain),
        getDomainName(custom_domain, true)
      )
        .then(r => this.setState({ updating: false, success: true }))
        .catch(err => {
          console.log(err);
          if (err === GQL_ERROR_DUPLICATE) {
            this.setState({
              updating: false,
              error: "The domain is already taken!"
            });
          }
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate();
    }
    return false;
  }

  render() {
    const { sub_domain, custom_domain, updating, success, error } = this.state;
    const siteUrl = getDomainName(config.SITE_URL, true);
    return (
      <div>
        <div className="mb-5">
          <div className="text-muted p-2 h6 mb-3 border-bottom">
            Your Domains:
          </div>
          {success && <p className="mx-3 alert alert-success">Updated successfully!</p>}
          {error && <p className="mx-3 alert alert-danger">{error}</p>}
          <div>
            <div className="row col mt-4">
              <FormElement
                className="col-6 pr-1"
                label="Dedicated Subdomain"
                placeHolder="ex: happy"
                name="sub_domain"
                value={sub_domain}
                onChange={this.onChange}
                validator={this.validator}
                validation="required|min:4"
              />
              <div className="col-3 pl-0 align-self-end pb-3">
                {`.${siteUrl}`}
              </div>
            </div>

            <div className="col col-11 bg-light ml-3 py-2 text-muted">
              {!sub_domain ? (
                <p className="">
                  {`Note: This will be your dedicated URL on ${siteUrl}. ex:
                  jones.${siteUrl}. Just specify the subdomain name, do not
                  include any http or www. or append ${siteUrl}`}
                </p>
              ) : (
                <div>
                  <div className=" mb-1">
                    Your URL:{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://${sub_domain}.${siteUrl}/`}
                    >{`https://${sub_domain}.${siteUrl}/`}</a>
                  </div>
                  <p className="">
                    Admin Login:{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://${sub_domain}.${siteUrl}/vendor/login`}
                    >{`https://${sub_domain}.${siteUrl}/vendor/login`}</a>
                  </p>
                </div>
              )}
            </div>

            <FormElement
              className="col-10 mt-5"
              label="Your Domain"
              placeHolder="ex: www.happyclinics.com"
              value={custom_domain}
              name="custom_domain"
              onChange={this.onChange}
              validator={this.validator}
              validation=""
            />
            <p className="col col-11 bg-light ml-3 py-2 text-muted">
              {`This will be the URL of your own domain that will be pointing to
              your dedicated subdomain on ${siteUrl}. Exclude specifyng https or
              www`}
            </p>
          </div>
        </div>

        <div className="col my-3">
          <Button
            btnCls="btn-outline-success px-5"
            iconCls="fa-save"
            value="Update"
            onClick={this.onFormSubmit}
            loading={updating}
          />
        </div>
      </div>
    );
  }
}
