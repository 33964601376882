import React from "react";
import { Link } from "react-router-dom";
import { getUserId } from "utils";

export default function IHMWelcome(props) {
	return (
		<div>
			<div className="intro text-muted" >
				<p className="mb-4 lead text-secondary">
					Welcome to the ActivDoctors Health Risk Estimator
				</p>
				<p className="" style={{ textDecoration: "underline" }}>
					The risk calculator estimates the likelihood of having a
					heart attack or stroke in the next five years.
				</p>
				<p className="">
					CVD is largely preventable, with modifiable CVD risk factors
					accounting for up to 90% of the risk of myocardial
					infarction1. In Australia, 64% of adults have three or more
					modifiable risk factors for CVD
				</p>
				<p className="" style={{ textDecoration: "underline" }}>
					How is the CVD Risk Calculator used? 
				</p>
				<p className="">
					The ActivDoctors CVD risk calculator calculates a risk
					score, expressed as a percentage, which is a person’s chance
					of having a heart attack or stroke in the next five years.
					The absolute risk calculator is based on the prediction
					equation known as the Framingham Risk Equation. This
					equation has been tested for its validity and has shown to
					have good predictive ability.
				</p>
			</div>
			<div className="cta my-5 text-center">
				<button
					className="btn btn-primary mb-3 px-5 py-2 text-light"
					onClick={() => props.setStart(true)}
				>
					Start
				</button>
				{!getUserId() && (
					<div>
						<Link to="/login" className="text-secondary">
							I have already taken the test
						</Link>
					</div>
				)}
			</div>
		</div>
	);
}
