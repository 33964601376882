// ennivronment specific config variables
const env_config = {
  development: {
    ENABLE_CONSOLE_LOG: true,
    ENABLE_REDUX_CHROME_EXT: false,
    TOKBOX_EMBED_ID: '31db5c84-edd6-4735-a3ea-613e3b65fb0e',
    RAZORPAY_ID: "rzp_test_6yzi8Gu6IEphsI",
    //PAYPAL_CLIENT_ID: 'AZ8Je6eXuHcBTxgFTxI4r32zRBrNX2cWYwDRqeW-kmeT-SgUOWTq9bGVlK4dKzmWGvQxgYs6UKC5gEyY',
    PAYPAL_CLIENT_ID: 'AWHhahQIx6rKfBjOxAH_C9QU4JBhQm71oiA8Zwkm6NGOfkvzWdWeF4ubogHAu7LD8HGW9BPAMFt6B5Qj',
    SITE_URL: process.env.REACT_APP_DEV_SITE_URL || "https://activ-doctors.com",
    GQL_ENDPOINT: process.env.REACT_APP_ADO_DEV_GQL_EP
  },
  production: {
    ENABLE_CONSOLE_LOG: false,
    ENABLE_REDUX_CHROME_EXT: false,
    RAZORPAY_ID: "rzp_live_zulyU9Osv0lmdO",
    TOKBOX_EMBED_ID: '881a03c6-3f18-453a-a002-bdf5facb32b2',
    PAYPAL_CLIENT_ID: 'AWHhahQIx6rKfBjOxAH_C9QU4JBhQm71oiA8Zwkm6NGOfkvzWdWeF4ubogHAu7LD8HGW9BPAMFt6B5Qj',
    SITE_URL: "https://www.activdoctors.com",
    GQL_ENDPOINT: process.env.REACT_APP_ADO_LIVE_GQL_EP
  }
};

// common variables across environments
const config = {
  ...env_config[process.env.REACT_APP_ENV || 'development'],  // export NODE_ENV=development
  ENV: process.env.REACT_APP_ENV || 'development',
  API_ENDPOINT: '', // accessed via proxy 
  RECAPTCHA_KEY : process.env.REACT_APP_RECAPTCHA_KEY,
  GOOGLE_PLACES_KEY : process.env.REACT_APP_GOOGLE_PLACES_KEY
};

export default config;