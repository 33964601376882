import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { getUserId, isSMDevice, getSubRole } from "utils";
import {
    A4P_VENDOR_ID,
    APP_IS_PETS_ENABLED,
    APP_SUPPORT_PHONE,
    APP_NAME,
    A4P_VENDOR_TYPE,
} from "data/config/variables";

class AppSidebar extends Component {
    constructor(props) {
        super(props);

        // user side menu
        this.userMenu = [
            /* { path: "/app", icon: "dashboard", title: "Dashboard" }, */
            {
                path: "/app/my-health",
                icon: "local_hospital",
                title: "My Health",
            },
            {
                path: "/app/my-appointments",
                icon: "date_range",
                title: "My Appointments",
            },
            {
                path: "/app/medical-records",
                icon: "storage",
                title: "Medical Records",
            },
        ];

        // vendor side menu
        this.vendorMenu = [
            /* { path: "/app", icon: "dashboard", title: "Dashboard" }, */
            {
                path: "/app/appointments",
                icon: "date_range",
                title: "My Appointments",
            },
            { path: "/app/listings", icon: "dns", title: "My Listings" },
            { path: "/app/settings", icon: "settings", title: "Settings" },
        ];

        if (getSubRole() === A4P_VENDOR_TYPE.ROOT_ADMIN) {
            this.vendorMenu.push({
                path: "/app/reports",
                icon: "bar_chart",
                title: "Reports",
            });
        }

        // add my pets for pets website
        if (APP_IS_PETS_ENABLED) {
            this.userMenu.push({
                path: "/app/my-pets",
                icon: "pets",
                title: "Manage Pets",
            });
        }

        // my profile
        this.userMenu.push({
            path: "/app/my-profile",
            icon: "person",
            title: "My Profile",
        });

        // add phr for a4p vendor
        if (getUserId() === A4P_VENDOR_ID) {
            this.vendorMenu.push({
                path: "/app/users",
                icon: "account_box",
                title: "Users",
            });
        }

        if (isSMDevice()) {
            const logout = {
                path: "/app/logout",
                icon: "not_interested",
                title: "Logout",
            };
            this.vendorMenu.push(logout);
            this.userMenu.push(logout);
        }
    }

    render() {
        const { sideMenu, onSideMenuTogl } = this.props;
        let pt = this.props.location.pathname;
        let menu =
            this.props.auth.user.role === "vendor"
                ? this.vendorMenu
                : this.userMenu;

        return (
                <div className="bg-primary">
                    <ul className="container nav navbar justify-content-start">
                        {menu.map((m, k) => (
                            <li
                                key={k}
                                className={`nav-item mr-lg-4 ${
                                    pt.includes(m.path) ? "active" : ""
                                }`}
                            >
                                <Link
                                    to={m.path}
                                    className={
                                        pt === m.path ||
                                        (k && pt.includes(m.path))
                                            ? "nav-link text-tertiary"
                                            : "nav-link text-white"
                                    }
                                >
                                    <i
                                        className={
                                            pt === m.path ||
                                            (k && pt.includes(m.path))
                                                ? "material-icons text-warning"
                                                : "material-icons text-white"
                                        }
                                    >
                                        {m.icon}
                                    </i>
                                    {m.title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </div>
        );
    }
}

export default withRouter(AppSidebar);
