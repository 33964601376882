import moment from "moment-timezone";

export const DAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const DAYS2 = DAYS.map(d => {
    return { value: d, label: d };
});

export const TIMEZONES = moment.tz.names().map(zone => {
    return { value: zone, label: zone };
});
