import React from 'react'
import PropTypes from 'prop-types';

import ReCAPTCHA from "react-google-recaptcha"; 
import config from 'data/config';

export default function ReCaptcha({onChange}) {
  return (
    <ReCAPTCHA
      sitekey={config.RECAPTCHA_KEY}
      onChange={onChange}
    />
  )
}

ReCaptcha.propTypes = {
  onChange: PropTypes.func.isRequired
};