import React, { Component } from "react";
import FormElement from "../form/FormElement";
import SimpleReactValidator from "simple-react-validator";
import ReactDatePicker from "react-datepicker";
import Button from "../form/Button";
import { getUserId, getUser, getUserRole, getSubDomain } from "utils";
import {
    A4P_VENDOR_ID,
    A4P_PAYMENT_TYPES,
    A4P_ROLE_VENDOR,
    APP_CURRENCY_SYMBOL
} from "data/config/variables";
import moment from "moment-timezone";
import { processPayment } from "./network";

export default class BookAppointmentPayment extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.validator = new SimpleReactValidator({});
        this.state = {
            processing: false,
            subDomain: getSubDomain() || {},
            error: null,
            selectedDate: null,
            chargePayment: true,
            name: "",
            cardNumber: "",
            expDt: "",
            ccv: ""
        };
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onFormSubmit(e) {
        e.preventDefault();
        if (this.validator.allValid()) {
            let { cardNumber, name, ccv, expDt, chargePayment, subDomain } = this.state;
            const { listing, slot, info } = this.props;
            const { title, line1, line2, address, avatar } = listing;
            const { type, duration, price } = slot.service;

            const { user, pet } = info;
            const { first_name, last_name, pic, phone, email, id } = user;

            const data = {
                paymentType: A4P_PAYMENT_TYPES.APPOINTMENTS,
                user_id: user.id,
                vendor_id: listing.vendor_id,
                vendor_listing_id: listing.id,
                slot: slot.time,
                type,
                userTZ: slot.timezone,
                listingTZ: listing.timezone,
                price: chargePayment ? parseInt(price) : 0,
                taxes: 0,
                user: { first_name, last_name, pic, phone, email, id },
                pet,
                subDomain,
                details: {
                    title,
                    line1,
                    line2,
                    address,
                    avatar,
                    duration,
                    price
                },
                info,
                payment: {
                    cardNumber,
                    name,
                    ccv,
                    expDt
                }
            };

            console.log(data);
            this.setState({ processing: true });
            processPayment(data)
                .then(r => {
                    console.log(r);
                    if (r.result === "Error")
                        this.setState({ processing: false, error: r.status });
                    else {
                        this.setState({ processing: false });
                        this.props.onComplete(r.id);
                    }
                })
                .catch(err => console.log(err));
        } else {
            this.validator.showMessages();
            this.validator.getErrorMessages();
            this.forceUpdate(); // [validator] rerender to show messages for the first time
        }
    }

    render() {
        const { selectedDate, processing, error, chargePayment } = this.state;
        const { slot } = this.props;
        const { price } = slot.service;
        const userRole = getUserRole();
        this.validator.purgeFields(); 

        return (
            <div>
                <div className="my-3">
                    <p className="h6 text-muted">Payment:</p>
                </div>

                <div className="p-3 border">
                    {error && <div className="alert alert-danger">{error}</div>}
                    {userRole === A4P_ROLE_VENDOR && (
                        <div className="my-2">
                            <div className="form-group custom-control custom-switch">
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id="chargePayment"
                                    checked={chargePayment}
                                    onChange={e =>
                                        this.setState({
                                            chargePayment: !chargePayment
                                        })
                                    }
                                />
                                <label
                                    className="custom-control-label"
                                    htmlFor="chargePayment"
                                >
                                    {chargePayment
                                        ? "Enabled"
                                        : "Payment Waived"}
                                </label>
                            </div>
                        </div>
                    )}
                    {chargePayment && (price != 0) && (
                        <div className="row">
                            <div className="col-md-8 col-sm-12">
                                <div className="form_title my-3">
                                    <h4>
                                        <strong>1</strong>Payment Information
                                    </h4>
                                </div>

                                <div className="row">
                                    <div className="col-md-8 col-sm-12">
                                        <FormElement
                                            className=""
                                            name="name"
                                            label="Name on the Card"
                                            placeHolder="ex: Jonh Lathum"
                                            state={this.state}
                                            onChange={this.onChange}
                                            validator={this.validator}
                                            validation="required"
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-8 col-sm-12">
                                        <FormElement
                                            label="Credit Card Number"
                                            className=""
                                            name="cardNumber"
                                            placeHolder="xxxx - xxxx - xxxx - xxxx"
                                            state={this.state}
                                            onChange={this.onChange}
                                            validator={this.validator}
                                            validation="required|numeric|min:13|max:16"
                                        />
                                    </div>
                                    <div className="d-none d-md-block pt-4 mt-2">
                                        <img
                                            src="/img/payments-methods.png"
                                            alt="Cards"
                                            className="cards"
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-4 col-sm-6">
                                        <div className="form-group">
                                            <label className="text-muted">
                                                Expiry Date:
                                            </label>
                                            <ReactDatePicker
                                                className="form-control"
                                                selected={selectedDate}
                                                onChange={selectedDate =>
                                                    this.setState({
                                                        selectedDate,
                                                        expDt: moment(
                                                            selectedDate
                                                        ).format("YYYY-MM")
                                                    })
                                                }
                                                showMonthYearPicker
                                                dateFormat="MMM yyyy"
                                            />
                                            {this.validator.message(
                                                "expiry",
                                                selectedDate,
                                                "required"
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-md-4 col-sm-6">
                                        <FormElement
                                            className=""
                                            label="Security Code"
                                            name="ccv"
                                            placeHolder="CCV"
                                            state={this.state}
                                            onChange={this.onChange}
                                            validator={this.validator}
                                            validation="required|numeric|min:3|max:4"
                                        />
                                    </div>
                                    <div className="d-none d-md-block pt-4 mt-2">
                                        <img
                                            src="/img/icon_ccv.gif"
                                            width={50}
                                            height={29}
                                            alt="ccv"
                                        />
                                        <small>Last 3 digits</small>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-sm-12">
                                <div className="form_title my-3">
                                    <h4>
                                        <strong>2</strong>Billing
                                    </h4>
                                </div>

                                <p>Appointment Confirmation:</p>
                                <div className="summary">
                                    <ul className="list-unstyled">
                                        <li>
                                            Item Cost:
                                            <strong className="float-right">
                                                {APP_CURRENCY_SYMBOL + price}.00
                                            </strong>
                                        </li>
                                        <li>
                                            Taxes:
                                            <strong className="float-right">
                                                NA
                                            </strong>
                                        </li>
                                    </ul>
                                </div>
                                <ul className="checkout clearfix list-unstyled">
                                    <li className="total font-weight-bold">
                                        Total{" "}
                                        <strong className="price">
                                            {APP_CURRENCY_SYMBOL + price}.00
                                        </strong>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    )}
                    <div className="mt-3 mb-1">
                        <Button
                            btnCls="btn-primary"
                            iconCls="fa-credit-card"
                            value="Confirm and Pay"
                            loading={processing}
                            onClick={this.onFormSubmit}
                        />
                    </div>
                    <small style={{ color: "#ccc" }}>
                        We do not store your credit card details on our servers.
                    </small>
                </div>
            </div>
        );
    }
}
