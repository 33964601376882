import React, { Component } from "react";
import PHRIndex from "pages/app/components/phr";
import * as qs from "query-string";
import { getUserIdFromHash } from "./network";

export default class PHRView extends Component {
	constructor(params) {
		super(params);
		this.state = { id: null };
	}

	componentDidMount() {
		const hash = qs.parse(this.props.location.search).id;

		getUserIdFromHash(hash)
			.then((r) => {
				const [user] = r.data.users;
				if (user) this.setState({ id: user.id });
			})
			.catch((err) => console.log(err));
	}

	render() {
		const { id } = this.state;
		return <div>{id && <PHRIndex userId={id} />}</div>;
	}
}
