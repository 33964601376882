import React, { Component } from "react";
import { Link } from "react-router-dom";

class SectionHead extends Component {
    render() {
        const {
            createTxt,
            onCreateLinkClick,
            onCreateClick,
            onGoBack
        } = this.props;
        return (
            <div>
                <div
                    className="d-flex mb-4"
                    style={{ borderBottom: "1px solid #f2f2f2" }}
                >
                    {onGoBack && (
                        <button
                            title="Home"
                            className="btn btn-outline-secondary px-3 mr-3"
                            onClick={onGoBack}
                            style={{ height: "fit-content" }}
                        >
                            <i
                                className="material-icons"
                                style={{ verticalAlign: "bottom" }}
                            >
                                keyboard_backspace
                            </i>
                        </button>
                    )}

                    <div
                        style={{
                            color: "#555",
                            fontWeight: 300,
                            fontSize: "1.65em",
                            paddingBottom: "0.5em"
                        }}
                    >
                        {this.props.title}
                    </div>

                    {onCreateLinkClick && (
                        <Link
                            title="Home"
                            className="btn btn-outline-success px-3 ml-auto"
                            to={this.props.createLink}
                            style={{ height: "fit-content" }}
                        >
                            <i
                                className="material-icons mr-2"
                                style={{ verticalAlign: "bottom" }}
                            >
                                note_add
                            </i>
                            {createTxt}
                        </Link>
                    )}
                    {onCreateClick && (
                        <a
                            title="Home"
                            className="btn btn-outline-success px-3 ml-auto"
                            href="#!"
                            style={{ height: "fit-content" }}
                            onClick={onCreateClick}
                        >
                            <i
                                className="material-icons mr-2"
                                style={{ verticalAlign: "bottom" }}
                            >
                                note_add
                            </i>
                            {createTxt}
                        </a>
                    )}
                </div>
            </div>
        );
    }
}

export default SectionHead;
