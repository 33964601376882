import React, { Component } from "react";

export default class Button extends Component {
    render() {
        const { loading, btnCls, iconCls, value, onClick } = this.props;
        const mr2 = value?"mr-2":"";
        return (
            <button className={`btn ${btnCls}`} onClick={onClick} disabled={loading}>
                <i className={`${loading ? `${mr2} fa fa-spinner fa-spin` : iconCls ? `${mr2} fa ` + iconCls : ""}`}></i>
                {value}
            </button>
        );
    }
}
