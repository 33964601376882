import { gqlSend } from "utils/AxiosUtil";
import { APP_IS_PETS_ENABLED } from "data/config/variables";

export const createOrUpdate = (id, pet_id, user_id, category, details) => {
  const subQuery = id
    ? `update_phr( where: {id: {_eq: $id}}, _set: {details: $details} ) {
      affected_rows
    }`
    : `insert_phr(objects: {user_id: $user_id, pet_id: $pet_id, category: $category, details: $details}) {
      returning {id}
    }`;

  const q = {
    query: ` 
        mutation ($id: Int, $user_id: Int, $pet_id: Int, $category: String, $details: jsonb) {
          ${subQuery}
        }`,
    variables: {
      id,
      user_id,
      pet_id,
      category,
      details
    }
  };

  return gqlSend(q);
};


export const getPHR = (user_id, pet_id) => {
  const whr = APP_IS_PETS_ENABLED? `{pet_id: {_eq: ${pet_id}}}`: `{user_id: {_eq: ${user_id}}}`;
  const q = {
    query: `{
              phr(where: ${whr}, order_by: {created_at: desc}) {
                id, category, details
              }
            }`
  };
  return gqlSend(q);
};

export const deletePHR = id => {
  return gqlSend({
    query: ` 
                mutation {
                    delete_phr(where: {id: {_eq: ${id}}}) {
                        affected_rows
                      }
                }
                `
  });
};
