import { PUBLIC_SEARCH_PARAMS } from "../../actions/types";

const initialState = { 
  location: "",
  keyword: ""
};

export default function(state = initialState, action) {
  switch (action.type) {
      case PUBLIC_SEARCH_PARAMS: 
          return {
              ...state,
              location: action.payload.location,
              keyword: action.payload.keyword
          }
      default:
          return state;
  }
}