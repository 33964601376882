import React from "react";

const FormElement = props => (
    <div className={props.className} key={props.name}>
        <div className="form-group">
            {props.children ? (
                props.children
            ) : (
                <div>
                    <label className="text-muted">{props.label || props.placeHolder}: </label>
                    <input
                        type={props.type || "text"}
                        className="form-control"
                        placeholder={props.placeHolder}
                        onChange={props.onChange}
                        name={props.name || ""}
                        value={
                            props.value
                                ? props.value
                                : props.state && props.state[props.name]
                                ? props.state[props.name]
                                : ""
                        }
                        title={props.title}
                        disabled={props.disabled}
                    />
                </div>
            )}
            {props.validation &&
                props.validator.message(
                    props.name || props.label,
                    props.value ? props.value : props.state && props.state[props.name] ? props.state[props.name] : null,
                    props.validation
                )}
        </div>
    </div>
);

export default FormElement;
