import React, { Component } from "react";
import Select from "react-select";
import MyDropZone from "pages/components/form/MyDropZone";
import { profile_pic_types } from "data/config/variables";
import isEmpty from "utils/isEmpty";

class CRUDFormElement extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderElement() {
    const { props } = this;
    const type = props.type;
    switch (type) {
      case "dropdown":
        return (
          <Select
            name={props.name}
            options={props.data}
            //isMulti={true}
            placeholder={props.placeHolder}
            value={props.data.map(op =>
              props.state[props.name] &&
              props.state[props.name].includes(op.value)
                ? op
                : false
            )}
            onChange={sel => props.onChange(props.name, sel.value)}
          />
        );
      case "text-area":
        return (
          <textarea
            name={props.name}
            value={props.state[props.name] ? props.state[props.name] : ""}
            rows="3"
            className="form-control"
            placeholder={props.placeHolder}
            value={props.state[props.name] ? props.state[props.name] : ""}
            onChange={e => props.onChange(props.name, e.target.value)}
          />
        );
      case "file":
        return (
          <div>
            <MyDropZone
              placeHolder="Drag and Drop files here or Click to browse"
              uploadType={profile_pic_types.phr}
              acceptTypes="*"
              height={80}
              canRemove={false}
              onUpload={files =>
                props.onChange(props.name, [
                  ...new Set(files.concat(props.state[props.name] || []))
                ])
              }
            />
            {!isEmpty(props.state[props.name]) && (
              <table className="table table-sm mt-3">
                <thead className="thead-dark">
                  <tr>
                    <th className="w-75">File</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {props.state[props.name].map((file, k1) => (
                    <tr key={k1}>
                      <td>{file.substr(file.lastIndexOf("/") + 1)}</td>
                      <td>
                        <a
                          row-id={k1}
                          className="edit mr-2"
                          title="Download"
                          data-toggle="tooltip"
                          target="_blank" rel="noopener noreferrer"
                          href={file}
                          download
                        >
                          <i className="material-icons">cloud_download</i>
                        </a>
                        <a
                          row-id={file}
                          className="delete"
                          title="Delete"
                          data-toggle="tooltip"
                          href="#!"
                          onClick={e => {
                            if ( window.confirm(  "Are you sure you wish to delete this item?" ) ) {
                              const filteredItems = props.state[props.name].filter(i => i !== file);
                              console.log(file, filteredItems);
                              props.onChange(props.name, filteredItems);
                            }
                          }}
                        >
                          <i className="material-icons">delete</i>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        );
      default:
        return (
          <input
            type={type || "text"}
            className="form-control"
            placeholder={props.placeHolder}
            onChange={e => props.onChange(props.name, e.target.value)}
            name={props.name}
            value={props.state[props.name] ? props.state[props.name] : ""}
            title={props.title}
            disabled={props.disabled}
          />
        );
    }
  }

  render() {
    const { props } = this;
    return (
      <div className={props.className} key={props.name}>
        <div className="form-group">
          <label className="text-muted">{`${props.label}${props.validation?"*":""}`}</label>

          {props.children ? props.children : this.renderElement()}

          {props.validation &&
            props.validator.message(
              props.name,
              props.state[props.name],
              props.validation
            )}
        </div>
      </div>
    );
  }
}

export default CRUDFormElement;
