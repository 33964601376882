import React, { useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import {
	Col,
	Row,
	Tooltip,
	Form,
	FormGroup,
	Label,
	Input,
	CustomInput,
	FormFeedback,
	FormText,
} from "reactstrap";
import { BottomNav } from ".";

export default function Step2(props) {
	const [, forceUpdate] = useState(false);
	const validator = useRef(new SimpleReactValidator());

	const [form, saveForm] = useState({});
	const submitForm = (event) => {
		event.preventDefault();
		if (validator.current.allValid()) {
			form.step2complete = true;
			props.saveForm(form);
			props.nextStep();
		} else {
			validator.current.showMessages();
			forceUpdate();
		}
	};

	const onChange = (e) => {
		let val = e.target.value;
		if (e.target.type === "checkbox") {
			val = e.target.checked;
		}
		saveForm({ ...form, [e.target.name]: val });
	};

	return (
		<div>
			<h4 className=" pb-3 text-center">Life Style Information</h4>
			<Form onSubmit={submitForm}>
				<Row>
					<Col className="mb-2" md={6}>
						<FormGroup>
							<Label for="exampleAddress">
								How many glasses of alcohol do you consume per
								week?
								<span className="text-danger">*</span>
							</Label>
							<Input
								type="text"
								name="l-alc"
								placeholder="0"
								onChange={onChange}
							/>
							{validator.current.message(
								"glasses",
								form["l-alc"],
								"required|integer|min:0,num|max:300,num"
							)}
						</FormGroup>
					</Col>
					<Col className="mb-2" md={6}>
						<FormGroup>
							<Label for="exampleAddress">
								How many minutes of exercise do you do per week?
								<span className="text-danger">*</span>
							</Label>
							<Input
								type="text"
								name="l-excer"
								placeholder="90"
								onChange={onChange}
							/>
							{validator.current.message(
								"minutes",
								form["l-excer"],
								"required|integer|min:0,num|max:3000,num"
							)}
						</FormGroup>
					</Col>
					<Col className="mb-2" md={6}>
						<FormGroup>
							<Label for="exampleAddress">
								How many Cigarettes do you smoke per day?
							</Label>
							<Input
								type="text"
								name="l-cigd"
								defaultValue="0"
								onChange={onChange}
							/>
							{validator.current.message(
								"count",
								form["l-cigd"],
								"integer|min:0,num|max:100,num"
							)}
						</FormGroup>
					</Col>
					<Col className="mb-2" md={6}>
						<FormGroup>
							<Label for="exampleAddress">
								How many years have you been smoking Cigarettes?
							</Label>
							<Input
								type="years"
								name="l-cigy"
								defaultValue="0"
								onChange={onChange}
							/>
							{validator.current.message(
								"value",
								form["l-cigy"],
								"integer|min:0,num|max:100,num"
							)}
						</FormGroup>
					</Col>
					<Col className="mb-2" md={6}>
						<FormGroup>
							<Label for="exampleAddress">
								How many times do you eat out at fast-food
								restaurants weekly?
								<span className="text-danger">*</span>
							</Label>
							<Input
								type="text"
								name="l-junk"
								placeholder="0"
								onChange={onChange}
							/>
							{validator.current.message(
								"times",
								form["l-junk"],
								"required|integer|min:0,num|max:100,num"
							)}
						</FormGroup>
					</Col>
					<Col className="mb-2" md={6}>
						<FormGroup>
							<Label for="exampleCheckbox">
								Do You have a parent/sibling with diabetes?
							</Label>
							<div>
								<CustomInput
									inline
									type="radio"
									id="l-diabp-yes"
									name="l-diabp"
									value="yes"
									label="Yes"
									onChange={onChange}
								/>
								<CustomInput
									inline
									type="radio"
									id="l-diabp-no"
									name="l-diabp"
									label="No"
									value="no"
									onChange={onChange}
								/>
								<CustomInput
									inline
									type="radio"
									id="l-diabp-oth"
									name="l-diabp"
									label="Don't Know"
									value="no"
									onChange={onChange}
								/>
							</div>
						</FormGroup>
					</Col>
					<Col className="mb-2" md={6}>
						<FormGroup>
							<Label for="exampleCheckbox">
								Is your parent/sibling diagnosed with heart
								disease before age 50?
							</Label>
							<div>
								<CustomInput
									inline
									type="radio"
									label="Yes"
									id="l-heartp-yes"
									name="l-heartp"
									value="yes"
									onChange={onChange}
								/>
								<CustomInput
									inline
									type="radio"
									label="No"
									id="l-heartp-no"
									name="l-heartp"
									value="no"
									onChange={onChange}
								/>
								<CustomInput
									inline
									type="radio"
									label="Don't Know"
									id="l-heartp-oth"
									name="l-heartp"
									value="no"
									onChange={onChange}
								/>
							</div>
						</FormGroup>
					</Col>
					<Col className="mb-2" md={6}>
						<FormGroup>
							<Label for="exampleCheckbox">
								Have you ever worked or working in asbestos or
								chemical factory?
							</Label>
							<div>
								<CustomInput
									inline
									type="radio"
									label="Yes"
									id="l-asb-yes"
									name="l-asb"
									value="yes"
									onChange={onChange}
								/>
								<CustomInput
									inline
									type="radio"
									label="No"
									id="l-asb-no"
									name="l-asb"
									value="no"
									onChange={onChange}
								/>
								<CustomInput
									inline
									type="radio"
									label="Don't Know"
									id="l-asb-oth"
									name="l-asb"
									value="no"
									onChange={onChange}
								/>
							</div>
						</FormGroup>
					</Col>
				</Row>
			</Form>
			<BottomNav
				previousStep={props.previousStep}
				nextStep={submitForm}
			/>
		</div>
	);
}
