import React, { Component } from "react"; 
import MyProfile from "pages/app/components/MyProfile";

export default class SettingsProfileIndex extends Component {
    render() {
        return (
            <div className="mt-4 text-muted p-2 ">
                <MyProfile />
            </div>
        );
    }
}
