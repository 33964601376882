import axios from "axios";
import config from "data/config"; 

export const uploadAvatarPic = async (blob, serviceType) => {
  if (!blob) return null;

  const fileExt = blob.type.split("/")[1];
  const params = {
    fileName: `pic.${fileExt}`,
    fileType: blob.type,
    serviceType
  };

  const { fileUrl, uploadUrl } = await axios
    .post(config.API_ENDPOINT + `/api/files/public`, params)
    .then(res => res.data)
    .catch(err => console.log(err));

  const status = await fetch(uploadUrl, { method: "PUT", body: blob })
    .then(res => res.status)
    .catch(err => console.log(err));

  return status === 200 ? fileUrl : null;
};

