import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { getUserId } from "utils";
import "./style.css";
import IHMRSteps from "./wizard";

export default function IHMIndex() {
	if (getUserId()) return <Redirect to="/app" />;
	return (
		<div className="ihm-wrapper">
			<div className="content">
				<div className=" px-3">
					<img
						className="my-3"
						src="/img/ado-logo.png"
						alt="ActivDoctors"
						style={{ width: "190px" }}
					/>
					<div className="mt-4">
						<IHMRSteps />
					</div>
				</div>
			</div>
		</div>
	);
}
