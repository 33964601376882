import { A4P_PAYMENT_TYPES, IHM_SERVICE_FEES, IHM_STATUS } from "data/config/variables";
import { processPayment } from "pages/components/BookAppointment/network";
import PayPal from "pages/components/PayPal";
import RazorPayCheckout from "pages/components/RazorPay";
import { updateIHMPaymentStatus } from "pages/ihm/wizard/network";
import React, { useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Button } from "reactstrap";
import { getUser, getUserId } from "utils";

export default function IHMReportBasic(props) {
	const [payNow, setPayNow] = useState(false);
	return (
		<div className="px-md-3">
			{payNow ? (
				<PayR />
			) : (
				<BasicReport {...props} setPayNow={setPayNow} />
			)}
		</div>
	);
}

const BasicReport = (props) => (
	<div>
		<p className="lead text-center mb-5">
			Please see below the risk profile based on the inputs provided by
			you
		</p>
		<hr className="mb-4" />

		<div className="my-5 d-flex border-bottom pb-3">
			<div className="h5 w-25">Cardiovascular</div>
			<div className="w-50 text-muted">
				Current cardiovascular system profile is poor. Further risks on
				your cardiovascular system are related to genetic disorders. You
				are advised to exercise 180mins per week and healthy diet.
			</div>
			<div className="w-25">
				<div className="m-auto" style={{ width: 100, height: 100 }}>
					<CircularProgressbar
						value={props.report.heart}
						text={`${props.report.heart}%`}
					/>
				</div>
			</div>
		</div>
		<div className="my-5 d-flex border-bottom pb-3">
			<div className="h5 w-25">Diabetes</div>
			<div className="w-50 text-muted">
				Current diabetes profile is average. Further risks on your
				diabetes system are related to genetic disorders. You are
				advised to exercise 180mins per week and healthy diet.
			</div>
			<div className="w-25">
				<div className="m-auto" style={{ width: 100, height: 100 }}>
					<CircularProgressbar
						value={props.report.diabetes}
						text={`${props.report.diabetes}%`}
						styles={buildStyles({
							pathColor: "green",
						})}
					/>
				</div>
			</div>
		</div>
		<div className="my-5 d-flex border-bottom pb-3">
			<div className="h5 w-25">Cancer</div>
			<div className="w-50 text-muted">
				Current cancer profile is poor. Further risks on your cancer are
				related to genetic disorders. You are advised to exercise
				180mins per week and healthy diet.
			</div>
			<div className="w-25">
				<div className="m-auto" style={{ width: 100, height: 100 }}>
					<CircularProgressbar
						value={props.report.bp}
						text={`${props.report.bp}%`}
						styles={buildStyles({
							pathColor: "red",
						})}
					/>
				</div>
			</div>
		</div>

		<div className="my-5 text-center">
			<Button
				color="primary"
				outline
				className="mr-3"
				onClick={(e) => props.onRetake()}
			>
				Start Again
			</Button>
			<Button color="warning" onClick={(e) => props.setPayNow(true)}>
				Know More...
			</Button>
		</div>
	</div>
);

const PayR = (props) => {
	const user = getUser();
	return (
		<div className="text-center">
			<h3 className="mb-3">Talk to a Doctor</h3>
			<hr className="my-4" />
			<p className="text-muted">
				Get a more accurate analysis based on lab tests and interaction
				with a specialized doctor for advise on future course of actions
			</p>

			<h1 className="mt-4 ml-3">${IHM_SERVICE_FEES}</h1>

			<div className="mt-3">
				<ul
					style={{
						listStyle: "none",
						lineHeight: "3em",
						color: "darkgray",
					}}
				>
					<li>
						<i className="fa fa-check mr-2" />
						Doctor Consultation
					</li>
					<li>
						<i className="fa fa-check mr-2" />
						Telephonic or Video Consultation
					</li>
					<li>
						<i className="fa fa-check mr-2" />
						E-Prescription
					</li>
					<li>
						<i className="fa fa-check mr-2" />
						Lab Test Prescription (Optional)
					</li>
					<li>
						<i className="fa fa-check mr-2" />
						Access to Medical Health Records
					</li>
					<li>
						<i className="fa fa-check mr-2" />
						Advise on Medications, Diet and Exercise
					</li>
				</ul>
			</div>

			<div className="my-5 d-flex justify-content-center">
				<PayPal
					amount={IHM_SERVICE_FEES}
					onComplete={(paymentInfo) => {
						console.log(paymentInfo);
						processPayment({
							userId: getUserId(),
							paymentType: A4P_PAYMENT_TYPES.IHM,
							IHMStatus: IHM_STATUS.PAID,
							price: IHM_SERVICE_FEES,
							paymentInfo,
						})
							.then((r) => {
								console.log(r);
								window.location = "/app";
							})
							.catch((err) => console.log(err));
					}}
				/>
				{/* <RazorPayCheckout
					amount={IHM_SERVICE_FEES * 100}
					actionTxt="Confirm and Pay"
					brandName={"ActivDr Online India"}
					brandLogo={"/img/ado-logo-sm.png"}
					name={`${user.first_name} ${user.last_name}`}
					email={user.email}
					phone={user.phone}
					onComplete={(r) => {
						updateIHMPaymentStatus(
							r.razorpay_payment_id,
							IHM_STATUS.PAID
						)
							.then((r) => (window.location = "/app"))
							.catch((err) => console.log(err));
					}}
				/> */}
			</div>
		</div>
	);
};
