import React, { Component } from "react";
import { getSubDomain, getDomainName } from "utils";
import RTCMain from "./main";
import config from "data/config";
import "./rtc.css";

export default class RTCIndex extends Component {
    render() {
        const subDomain = getSubDomain(); 

        return subDomain ? (
            <iframe
                allow="geolocation; microphone; camera"
                src={`${config.SITE_URL}?z=${this.props.location.z}`}
                scrolling="auto"
                frameBorder="0"
                className="FullHeight w-100"
            />
        ) : (
            <RTCMain z={this.props.location.z} />
        );
    }
}

const invalidLink = () => <div className="text-muted p-2">Invalid link</div>;
