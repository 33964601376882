import { IHM_STATUS, profile_pic_types } from "data/config/variables";
import BookAppointment from "pages/components/BookAppointment";
import MyDropZone from "pages/components/form/MyDropZone";
import { updateIHMBookingStatus } from "pages/ihm/wizard/network";
import React, { useState } from "react";

export default function IHMReportAdvanced(props) {
	const [labFiles, saveFiles] = useState([]);
	const [bookingConfirmed, setBookingConfirmed] = useState(false);
	console.log(props.report);

	const onComplete = (bookingId) => {
		console.log(bookingId, labFiles);
		updateIHMBookingStatus(
			bookingId,
			labFiles,
			IHM_STATUS.BOOKED_APPOINTMENT
		)
			.then((r) => setBookingConfirmed(true))
			.catch((r) => console.log(r));
	};

	return (
		<div className="px-md-3">
			{!bookingConfirmed && (
				<div>
					<h3 className="text-success text-center mb-5">
						Advanced Report
					</h3>
					<div className="text-muted">
						<h5 className=" mb-4">
							Please read the instructions below:
						</h5>

						<p>
							Congratulations on taking control of your health. In
							order to give you a more accurate detailed report we
							request you to get the below lab tests done, and
							upload the reports before you book an appointment
							with the doctor.
						</p>
						<p className="my-3">
							Based on the inputs provided from you we request you
							to get the following lab tests done and upload the
							reports.
						</p>

						<ul>
							{props.report.prescriptions.map((p, i) => (
								<li key={i}>{p}</li>
							))}
						</ul>

						<small>
							Note: Although lab tests are preferred, but kindly
							note that it is not mandatory to have the above lab
							tests done for booking an appointment with the
							doctor
						</small>
					</div>

					<hr className="my-4" />

					<h6>Upload lab reports here[Optional] </h6>
					<div>
						<MyDropZone
							placeHolder="Drag and Drop files here or Click to browse"
							uploadType={profile_pic_types.phr}
							acceptTypes="*"
							height={80}
							canRemove={false}
							onUpload={(files) => saveFiles(files)}
						/>
					</div>

					<hr className="my-4" />
					<h6 className="mt-4">Book Appointment</h6>
				</div>
			)}
			<BookAppointment vendor_id={1} onComplete={onComplete} />
		</div>
	);
}
