import React, { Component } from "react";
import PropTypes from "prop-types";
import AvatarEditor from "react-avatar-editor";
import Button from "./Button";
import { uploadAvatarPic } from "./network";

class ProfilePic extends Component {
    constructor(props) {
        super(props);
        this.state = {
            profilePic: this.props.avatar,
            profilePicScale: 1.2, // default
            type: this.props.type,
            width: this.props.width || 150,
            height: this.props.height || 150,

            showUploadBtn: false,
            uploading: false,
            showUploadSuccess: false
        };
        this.avatarEditor = null;
        this.onAvatarChange = this.onAvatarChange.bind(this);
        this.onScaleChange = this.onScaleChange.bind(this);
        this.onUploadClick = this.onUploadClick.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.avatar !== this.props.avatar)
            this.setState({profilePic: this.props.avatar});
    }

    // on profile pic change
    onAvatarChange(e) {
        this.setState({ showUploadSuccess: false, showUploadBtn: true, profilePic: e.target.files[0] });
    }

    // on profile pic zoom level change
    onScaleChange(e) {
        this.setState({ showUploadSuccess: false, showUploadBtn: true, profilePicScale: parseFloat(e.target.value) });
    }

    onUploadClick() {
        console.log("upload click");
        const { type } = this.state;
        this.setState({ uploading: true });
        if (this.avatarEditor.props.image) {
            this.avatarEditor.getImageScaledToCanvas().toBlob(blob =>
                uploadAvatarPic(blob, type)
                    .then(res => {
                        this.setState({ showUploadSuccess: true, showUploadBtn: false, uploading: false });
                        this.props.onUpload(res);
                    })
                    .catch(e => console.log(e))
            );
        }
    }

    render() {
        const { showUploadBtn, uploading, profilePic, profilePicScale, showUploadSuccess, width, height } = this.state;
        return (
            <div className="text-center">
                <div className="input-group mb-3" style={{maxWidth: "230px"}}>
                    <div className="custom-file">
                        <input
                            type="file"
                            onChange={this.onAvatarChange}
                            className="custom-file-input"
                            id="inputGroupFile02"
                        />
                        <label className="custom-file-label text-justify" htmlFor="inputGroupFile02">
                            Choose file
                        </label>
                    </div>
                </div>

                <AvatarEditor
                    ref={editor => (this.avatarEditor = editor)}
                    image={profilePic}
                    width={width}
                    height={height}
                    border={20}
                    color={[238, 238, 238, 0.6]} // RGBA
                    scale={profilePicScale}
                    rotate={0}
                    style={{ border: "1px dashed #ccc" }}
                />

                <div>
                    <input
                        name="scale"
                        type="range"
                        onChange={this.onScaleChange}
                        min="0.1"
                        max="2"
                        step="0.01"
                        defaultValue="1"
                    />
                </div>


                {showUploadBtn && (
                    <div className="mt-2">
                        <Button
                            btnCls="btn-outline-success btn-sm"
                            iconCls="fa-upload"
                            value="Upload"
                            loading={uploading}
                            onClick={this.onUploadClick}
                        />
                        <p className="mt-1 text-muted small">Click upload to save the image</p>
                    </div>
                )}
                {showUploadSuccess && <p className="mt-1 text-success small">Image Uploaded</p>}
            </div>
        );
    }
}

ProfilePic.propTypes = { 
    onUpload: PropTypes.func, 
};

export default ProfilePic;
