import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";
import { A4P_ROLE_USER, A4P_ROLE_VENDOR } from "data/config/variables";

import Header from "./layout/Header";
import Sidebar from "./layout/Sidebar";
import VendorListings from "./vendor/listing";
import VendorLiDetails from "./vendor/listing/details";
import UsersIndex from "./vendor/users";
import SettingsIndex from "./vendor/settings";
import UserAppointments from "./user/my-appointments";
import UserMecialRecords from "./user/medical-records";
import UserMyPets from "./user/my-pets";
import UserMyProfile from "./user/my-profile";
import VendorAppointments from "./vendor/my-appointments";
import { logoutUser } from "utils/LoginChecker";
import { isSMDevice, isMDDevice } from "utils";
import ReportsIndex from "./vendor/reports";
import UserMyHealth from "./user/my-health";

class UserHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sideMenu: isMDDevice() // true for md screens
        };
        this.onSideMenuTogl = this.onSideMenuTogl.bind(this);
    }

    onSideMenuTogl() {
        const { sideMenu } = this.state;
        if (isSMDevice())
            // only for sm devices
            this.setState({ sideMenu: !sideMenu });
    }

    render() {
        const { sideMenu } = this.state;
        const { auth } = this.props;
        return (
            <Fragment>
                {!auth.isAuthenticated ? (
                    <Redirect to="/404" />
                ) : (
                    <Fragment>
                        <Header onSideMenuTogl={this.onSideMenuTogl} />
                        <Sidebar
                            auth={auth}
                            sideMenu={sideMenu}
                            onSideMenuTogl={this.onSideMenuTogl}
                        />
                        {auth.user.role === A4P_ROLE_USER && (
                            <Switch>
                                {/* <Route path="/app" exact component={dashboard} /> */}
                                <Route
                                    exact
                                    path="/app"
                                    render={() => (
                                        <Redirect to="/app/my-health" />
                                    )}
                                />
                                <Route
                                    path="/app/my-health"
                                    exact
                                    component={UserMyHealth}
                                />
                                <Route
                                    path="/app/my-appointments"
                                    exact
                                    component={UserAppointments}
                                />
                                <Route
                                    path="/app/medical-records"
                                    exact
                                    component={UserMecialRecords}
                                />
                                <Route
                                    path="/app/my-pets"
                                    exact
                                    component={UserMyPets}
                                />
                                <Route
                                    path="/app/my-profile"
                                    exact
                                    component={UserMyProfile}
                                />
                                <Route
                                    exact
                                    path="/app/logout"
                                    render={() => {
                                        logoutUser();
                                    }}
                                />
                                <Redirect to="/404" />
                            </Switch>
                        )}
                        {auth.user.role === A4P_ROLE_VENDOR && (
                            <Switch>
                                {/* <Route path="/app" exact component={VendorDashboard} />  */}
                                <Route
                                    exact
                                    path="/app"
                                    render={() => (
                                        <Redirect to="/app/appointments" />
                                    )}
                                />
                                <Route
                                    path="/app/listings"
                                    exact
                                    component={VendorListings}
                                />
                                <Route
                                    path="/app/listings/item"
                                    exact
                                    component={VendorLiDetails}
                                />
                                <Route
                                    path="/app/appointments"
                                    exact
                                    component={VendorAppointments}
                                />
                                <Route
                                    path="/app/users"
                                    exact
                                    component={UsersIndex}
                                />
                                <Route
                                    path="/app/settings*"
                                    exact
                                    component={SettingsIndex}
                                />
                                <Route
                                    path="/app/reports*"
                                    exact
                                    component={ReportsIndex}
                                />
                                <Route
                                    exact
                                    path="/app/logout"
                                    render={() => {
                                        logoutUser();
                                    }}
                                />
                                <Redirect to="/404" />
                            </Switch>
                        )}
                    </Fragment>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, null)(UserHome);
