import React, { Component } from "react";
import { fetchBookingDetails } from "./network";
import moment from "moment-timezone";
import {
    A4P_SERVICE_ECONSULTATION,
    A4P_SERVICE_TALKTOAVET,
} from "data/config/variables";
import RTCVideo from "./video";
import RTCTeleConsult from "./teleConsult";
import { getBookingFromLink } from "pages/components/BookAppointment";

export default class RTCMain extends Component {
    constructor(props) {
        super(props);

        const { userId, bookingId, isVendor } = getBookingFromLink(
            this.props.z //"78U36" 78C36
        ); 

        this.state = {
            userId,
            bookingId,
            isVendor,
            isLoadingBooking: Boolean(userId && bookingId),
            isValidBooking: false,
            slot: null,
            details: null,
        };

        this.fetchBooking();
    }

    fetchBooking() {
        const { userId, bookingId } = this.state;

        if (userId && bookingId) {
            fetchBookingDetails(userId, bookingId)
                .then((r) => {
                    const [booking] = r.data.bookings;
                    if (booking) {
                        const { slot, details } = booking;console.log(booking)
                        this.setState({
                            isLoadingBooking: false,
                            isValidBooking: true,
                            slot,
                            details,
                        });
                    } else {
                        this.setState({
                            isLoadingBooking: false,
                            isValidBooking: false,
                        });
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    renderInfo() {
        const { slot, details, bookingId, isVendor } = this.state;
        const {
            type, details: { duration },
        } = details;console.log(type)

        const bufferTime = 3; // 3 mins early allowed
        const currTimeZone = moment.tz.guess();
        const currTime = moment().tz(currTimeZone); //2020-04-05T12:13:49+05:30

        const startTime = moment.tz(slot, currTimeZone);
        const endTime = moment.tz(slot, currTimeZone).add(duration, "minute");
        const startTimeWithBuffer = moment
            .tz(slot, currTimeZone)
            .subtract(bufferTime, "minute");

        console.log(`Current Time: ${currTime.format("DD-MMM-YYYY, hh:mm A z")},
			Slot StartTime: ${startTime.format("DD-MMM-YYYY, hh:mm A z")},
			Slot EndTime: ${endTime.format("DD-MMM-YYYY, hh:mm A z")}
		`);
/* 
        // if current time is before the slot starttime - your slot starts at time
        if (currTime.isBefore(startTimeWithBuffer))
            return (
                <div className="lead text-center text-muted">
                    <p className="pt-3">You are early!</p>
                    <p className="">{`You appointment is at ${startTime.format(
                        "hh:mm A z, Do MMMM,YYYY"
                    )}`}</p>
                </div>
            );
        // if current time is after the slot endtime  - invalid lin
        else if (currTime.isAfter(endTime)) return invalidLink();
 */
        // else display content
        if (type === A4P_SERVICE_ECONSULTATION)
            return <RTCVideo bookingId={bookingId} isVendor={isVendor} />;
        else if (type === A4P_SERVICE_TALKTOAVET)
            return <RTCTeleConsult bookingId={bookingId} isVendor={isVendor} />;

        return invalidLink();
    }

    render() {
        const { isLoadingBooking, isValidBooking } = this.state;

        return (
            <div>
                {isLoadingBooking ? (
                    <div className="text-muted p-2">loading...</div>
                ) : isValidBooking ? (
                    this.renderInfo()
                ) : (
                    invalidLink()
                )}
            </div>
        );
    }
}

const invalidLink = () => <div className="text-muted p-2">Invalid link</div>;
