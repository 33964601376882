import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Redirect, Switch } from "react-router-dom";

import config from "data/config";
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import PublicLogin from "./login";

import NotFound from "./404";
import privacyPolicy from "./privacy-policy";
import termsConditions from "./terms-conditions";
import ScrollToTop from "pages/components/ScrollToTop";
import VetSignup from "./for-vet/signup";
import SubHome from "./subHome";
import { getSubDomain } from "utils";
import * as qs from "query-string";

const LoadHeader = ({
    component: Component,
    auth,
    noHeader,
    showTransparentBG,
    ...rest
}) => (
    <Route
        {...rest}
        render={props => (
            <div>
                {!noHeader && (
                    <Header
                        loggedIn={auth.isAuthenticated}
                        showTransparentBG={showTransparentBG}
                    />
                )}
                <Component {...props}  />
                {!noHeader && <Footer  />}
            </div>
        )}
    />
);

class PublicHome extends Component {

    render() {
        const subDomain = getSubDomain(); 
        const auth = this.props.auth;

        // redirect to booking page 
        const z = qs.parse(this.props.location.search).z;
        if(z) return <Redirect to={{ pathname: "/appointments", z }} />;        

        return (
            <ScrollToTop>
                {subDomain ? (
                    <Switch>
                        <LoadHeader path="/" exact auth={auth}  component={SubHome} /> 
                        <LoadHeader path="/vendor/login" exact auth={auth}  component={VetSignup} />
                        <LoadHeader path="/login" exact auth={auth}  component={PublicLogin} />
                        <LoadHeader path="/signup" exact auth={auth}  component={PublicLogin} />
                        <LoadHeader path="/privacy-policy/" auth={auth} exact component={privacyPolicy} /> 
                        <LoadHeader path="/terms-conditions/" auth={auth} exact component={termsConditions} />
                        <LoadHeader auth={auth} component={NotFound} />  
                    </Switch>
                ) : (
                    <Switch>
                      <LoadHeader path="/" exact auth={auth} showTransparentBG={true} noHeader={true} component={VetSignup} /> 
                      <LoadHeader path="/vendor/signup" exact auth={auth} noHeader={true} component={VetSignup} />
                      <LoadHeader path="/vendor/login" exact auth={auth} noHeader={true} component={VetSignup} />
                      <LoadHeader path="/login" exact auth={auth} noHeader={true} component={PublicLogin} />
                      <LoadHeader path="/signup" exact auth={auth} noHeader={true} component={PublicLogin} />              
                
                      <LoadHeader auth={auth} noHeader={true} component={NotFound} />  
                    </Switch>
                )}
            </ScrollToTop>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, null)(PublicHome);
