import React, { useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import {
	Col,
	Row,
	Tooltip,
	Form,
	FormGroup,
	Label,
	Input,
	CustomInput,
	FormFeedback,
	FormText,
} from "reactstrap";
import { BottomNav } from ".";

export default function Step3(props) {
	const [, forceUpdate] = useState(false);
	const validator = useRef(new SimpleReactValidator());

	const [form, saveForm] = useState({});
	const submitForm = (event) => {
		event.preventDefault();
		if (validator.current.allValid()) {
			props.saveForm(form);
			props.submitSurvey();
		} else {
			validator.current.showMessages();
			forceUpdate();
		}
	};

	const onChange = (e) => {
		let val = e.target.value;
		if (e.target.type === "checkbox") {
			val = e.target.checked;
		}
		saveForm({ ...form, [e.target.name]: val });
	};

	return (
		<div>
			<h4 className=" pb-3 text-center">Additional Information</h4>
			<Form onSubmit={submitForm}>
				<FormGroup>
					<Label>Do you snore?</Label>
					<Input type="select" name="m-snore" onChange={onChange}>
						<option value=""></option>
						<option value="yes">Yes</option>
						<option value="no">No</option>
					</Input>
				</FormGroup>
				{form["m-snore"] === "yes" && (
					<FormGroup>
						<Label for="exampleCheckbox">
							Do you feel rested when you wake in the morning or
							fall asleep watching tv or take a nap in the noon?
						</Label>
						<Input
							type="select"
							name="m-snore-a"
							onChange={onChange}
						>
							<option value=""></option>
							<option value="yes">Yes</option>
							<option value="no">No</option>
						</Input>
					</FormGroup>
				)}

				<FormGroup>
					<Label for="exampleEmail">
						Have you had a colonoscopy?
					</Label>
					<Input type="select" name="m-colo" onChange={onChange}>
						<option value=""></option>
						<option value="yes">Yes</option>
						<option value="no">No</option>
					</Input>
				</FormGroup>
				{form["m-colo"] === "yes" && (
					<>
						<FormGroup>
							<Label for="exampleEmail">
								How many years ago?
							</Label>
							<Input
								type="text"
								name="m-colo-a"
								onChange={onChange}
							/>
							{validator.current.message(
								"years",
								form["m-colo-a"],
								"integer|min:0,num|max:100,num"
							)}
						</FormGroup>
						<FormGroup>
							<Label>
								Were there any polyps or growths removed?
							</Label>
							<Input
								type="select"
								name="m-colo-b"
								onChange={onChange}
							>
								<option value=""></option>
								<option value="yes">Yes</option>
								<option value="no">No</option>
							</Input>
						</FormGroup>
					</>
				)}
				{form["m-colo"] === "no" && (
					<>
						<FormGroup>
							<Label>
								Do you have a parent or sibling who had colon
								cancer or colon polyps before age 50?
							</Label>
							<Input
								type="select"
								name="m-colo-c"
								onChange={onChange}
							>
								<option value=""></option>
								<option value="yes">Yes</option>
								<option value="no">No</option>
							</Input>
						</FormGroup>
						<FormGroup>
							<Label for="exampleEmail">
								At what age if you remember?
							</Label>
							<Input
								type="text"
								name="m-colo-d"
								onChange={onChange}
							/>
							{validator.current.message(
								"minutes",
								form["m-colo-d"],
								"integer|min:0,num|max:100,num"
							)}
						</FormGroup>
					</>
				)}

				{props.form["g-sex"] === "female" && (
					<>
						<FormGroup>
							<Label>
								Did your mother or a sister have breast cancer
								before age 55?
							</Label>
							<Input
								type="select"
								name="m-bc"
								onChange={onChange}
							>
								<option value=""></option>
								<option value="yes">Yes</option>
								<option value="no">No</option>
							</Input>
						</FormGroup>
						<FormGroup>
							<Label>
								Has a parent or a sibling ever tested positive
								for a BRCA gene?
							</Label>
							<Input
								type="select"
								name="m-bc-a"
								onChange={onChange}
							>
								<option value=""></option>
								<option value="yes">Yes</option>
								<option value="no">No</option>
							</Input>
						</FormGroup>
						<FormGroup>
							<Label for="exampleEmail">
								How long(in years) ago was your last Mammogram?
							</Label>
							<Input
								type="text"
								name="m-bc-b"
								onChange={onChange}
								placeholder="0"
							/>
							{validator.current.message(
								"Mammogram",
								form["m-bc-b"],
								"integer|min:0,num|max:100,num"
							)}
						</FormGroup>
					</>
				)}
			</Form>
			<BottomNav
				previousStep={props.previousStep}
				nextStep={submitForm}
				nextLabel="Finish"
			/>
		</div>
	);
}
