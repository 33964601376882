import React, { Component } from "react";
import { CSSTransition } from "react-transition-group";

import Login from "./login";
import Signup from "./signup";
import { loginUser } from "utils/LoginChecker";
import { login } from "./network";
import {
    A4P_ROLE_VENDOR,
    A4P_VENDOR_TYPE_VET_DOCTOR
} from "data/config/variables";
import { getSubDomain } from "utils";

class VetSignup extends Component {
    constructor(props) {
        super(props);
        const pt = this.props.location.pathname;
        this.state = {
            showLogin: pt.includes("/login"),
            subDomain: getSubDomain()
        };

        if(pt === "/") this.state.showLogin = true;

        this.onLoginComplete = this.onLoginComplete.bind(this);
        this.onSignupComplete = this.onSignupComplete.bind(this);
        this.toggleShowLogin = this.toggleShowLogin.bind(this);
    }

    toggleShowLogin(e) {
        e.preventDefault();
        this.setState({ showLogin: !this.state.showLogin });
    }

    onSignupComplete(data) {
        login(data)
            .then(r => {
                if (r) this.onLoginComplete(r);
            })
            .catch(err => console.log(err));
    }

    onLoginComplete(data) {
        if (data) loginUser(data.token, "/app");
    }

    render() {
        const { subDomain, showLogin } = this.state;
        return (
            <div className="whiteHeaderOffset mb-5">
                <div className="container">
                    <div className="row col-sm-11 col-md-5  mx-auto p-4 shadow-lg rounded">
                        <h3 className="w-100 text-center mb-4">
                            Practice {" "} 
                            {showLogin ? "Login" : "Signup"}
                        </h3>
                        {showLogin ? (
                            <Login
                                role={A4P_ROLE_VENDOR}
                                type={A4P_VENDOR_TYPE_VET_DOCTOR}
                                onLoginComplete={this.onLoginComplete}
                            />
                        ) : (
                            <Signup
                                role={A4P_ROLE_VENDOR}
                                type={A4P_VENDOR_TYPE_VET_DOCTOR}
                                onSignupComplete={this.onSignupComplete}
                            />
                        )}
                        {/* {!subDomain && (
                            <p className="text-center link_bright mb-5">
                                {!this.state.showLogin ? (
                                    <span>
                                        Alraedy have an account?{" "}
                                        <a
                                            href="#0"
                                            onClick={this.toggleShowLogin}
                                        >
                                            <strong>Login here!</strong>
                                        </a>
                                    </span>
                                ) : (
                                    <span>
                                        Do not have an account yet?{" "}
                                        <a
                                            href="#0"
                                            onClick={this.toggleShowLogin}
                                        >
                                            <strong>Register now!</strong>
                                        </a>
                                    </span>
                                )}
                            </p>
                        )} */}
                    </div>
                </div>
            </div>
        );
    }
}

export default VetSignup;
