import React, { Component } from "react";
import { Link } from "react-router-dom";

export default class noRecords extends Component {
  render() {
    const {action, createLink} = this.props;
    return (
      <div>
        <div className="row d-flex flex-column align-items-center justify-content-center mt-5">
          <div >
            <img src="/img/empty.jpg" alt="Page Not Found" />
          </div>
          <div className="mt-4">
            <p className="lead ">Oops! Sorry no records here</p>
            <div className="mt-3 text-center">
              {action && <Link to={createLink} title="Home" className="btn btn-outline-success px-3" >
                <i
                  className="material-icons mr-2"
                  style={{ verticalAlign: "bottom" }}
                >
                  note_add
                </i>
                {action}
              </Link>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
