import React, { Component } from "react";
import PropTypes from "prop-types";

import SimpleReactValidator from "simple-react-validator";
import { login } from "./network";
import { A4P_ROLE_USER } from "data/config/variables";
import FormElement from "pages/components/form/FormElement";
import SocialLogin from "./social-logins";

class Login extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.validator = new SimpleReactValidator({}); 
    this.state = {
      email: "",
      password: "",
      role: this.props.role,
      submitBtnTxt: "Submit",
      serverErrors: false, // for displaying errors from server
      serverErrorMessage: "Sorry! Something went wrong" // error message frm server
    };
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value.trim() });
  }

  onFormSubmit(e) {
    e.preventDefault();

    if (this.validator.allValid()) {
      this.setState({
        submitBtnTxt: "Please Wait...",
        serverErrors: false,
        serverErrorMessage: ""
      });

      login(this.state)
        .then(r => {
          console.log(r);
          this.props.onLoginComplete(r);
        })
        .catch(err => {
          console.log(err);
          this.setState({
            submitBtnTxt: "Submit",
            serverErrors: true,
            serverErrorMessage: "Invalid email or password."
          });
        });
    } else {
      this.validator.showMessages();
      this.forceUpdate(); // [validator] rerender to show messages for the first time
    }
  }

  render() {
    const warningBlock = () => (
      <div className="alert alert-danger">
        <strong className="mr-2">Error!</strong> {this.state.serverErrorMessage}
      </div>
    );

    return (
      <div>
        <h4 className="text-center mb-4">Login below</h4>
        <div className="box_form">
         
          <div>
            {this.state.serverErrors && warningBlock()}
            <form id="vlogin" method="post" onSubmit={this.onFormSubmit}>
              <div className="row">
                <FormElement
                  name="email"
                  className="col-12"
                  placeHolder="Email"
                  state={this.state}
                  onChange={this.onChange}
                  validator={this.validator}
                  validation="required|email"
                />

                <FormElement
                  name="password"
                  type="password"
                  className="col-12"
                  placeHolder="Password"
                  state={this.state}
                  onChange={this.onChange}
                  validator={this.validator}
                  validation="required"
                />

                <p className="col-12 text-right mb-1">
                  <a href="#0" onClick={() => this.props.onForgotPWClick()}>
                    Forgot Password?
                  </a>
                </p>

                <div className="col-12 form-group text-center mt-3">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    value={this.state.submitBtnTxt}
                  >
                    {this.state.submitBtnTxt}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <p className="text-center link_bright mb-2">
        <span>
              Do not have an account yet?{" "}
              <a href="#0" onClick={()=>this.props.onSignupClick()}>
                <strong>Register now!</strong>
              </a>
            </span>
        </p>
      </div>
    );
  }
}

Login.defaultProps = {
  role: A4P_ROLE_USER
};

Login.propTypes = {
  onForgotPWClick: PropTypes.func.isRequired,
  onSignupClick: PropTypes.func.isRequired,
  onLoginComplete: PropTypes.func.isRequired,
  role: PropTypes.string
};

export default Login;
