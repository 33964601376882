import {
    RA_CONFIG_SET_CALPICKER,
    RA_CONFIG_SET_CHECKOUT_DATA,
    RA_UPDATE_SUBDOMAIN_INFO
} from "../actions/types";
import moment from "moment";

const initialState = {
    SubDomain: null,
    CalendarPickerDt: moment(),
    Checkout: null
};

export default function(state = initialState, action) {
    switch (action.type) {
        case RA_CONFIG_SET_CALPICKER:
            return {
                ...state,
                CalendarPickerDt: action.payload
            };
        case RA_CONFIG_SET_CHECKOUT_DATA:
            return {
                ...state,
                Checkout: action.payload
            };
        case RA_UPDATE_SUBDOMAIN_INFO:
            return {
                ...state,
                SubDomain: action.payload
            };
        default:
            return state;
    }
}
