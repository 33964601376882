import React, { useState, useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import {
	Col,
	Row,
	Tooltip,
	Form,
	FormGroup,
	Label,
	Input,
	CustomInput,
	FormFeedback,
	FormText,
} from "reactstrap";
import { BottomNav } from ".";

export default function Step1(props) {
	const [, forceUpdate] = useState(false);
	const validator = useRef(new SimpleReactValidator());

	const [form, saveForm] = useState({});
	const submitForm = (event) => {
		event.preventDefault();
		if (validator.current.allValid()) {
			form.step1complete = true;
			props.saveForm(form);
			props.nextStep();
		} else {
			validator.current.showMessages();
			forceUpdate(1);
		}
	};

	const onChange = (e) => {
		let val = e.target.value;
		if (e.target.type === "checkbox") {
			val = e.target.checked;
		}
		saveForm({ ...form, [e.target.name]: val });
	};

	return (
		<div>
			<h4 className=" pb-3 text-center">General Information</h4>
			<Form onSubmit={submitForm}>
				<Label className="my-3" for="exampleCheckbox">
					Diseases you already have been diagnosed with
				</Label>
				<Row>
					<Col className="mb-2" xs={6} md={4}>
						<CustomInput
							type="switch"
							id="g-pre-diab"
							name="g-pre-diab"
							label="Diabetes"
							onChange={onChange}
						/>
					</Col>
					<Col className="mb-2" xs={6} md={4}>
						<CustomInput
							type="switch"
							id="g-pre-hd"
							name="g-pre-hd"
							label="Heart Disease"
							onChange={onChange}
						/>
					</Col>
					<Col className="mb-2" xs={6} md={4}>
						<CustomInput
							type="switch"
							label="Sleep Apnea"
							id="g-pre-sa"
							name="g-pre-sa"
							onChange={onChange}
						/>
					</Col>
					<Col className="mb-2" xs={6} md={4}>
						<CustomInput
							type="switch"
							label="Prostate Cancer"
							id="g-pre-pc"
							name="g-pre-pc"
							onChange={onChange}
						/>
					</Col>
					<Col className="mb-2" xs={6} md={4}>
						<CustomInput
							type="switch"
							label="High Blood Pressure"
							id="g-pre-bp"
							name="g-pre-bp"
							onChange={onChange}
						/>
					</Col>
					<Col className="mb-2" xs={6} md={4}>
						<CustomInput
							type="switch"
							label="High Cholesterol"
							id="g-pre-chol"
							name="g-pre-chol"
							onChange={onChange}
						/>
					</Col>
					<Col className="mb-2" xs={6} md={4}>
						<CustomInput
							type="switch"
							id="g-pre-cc"
							name="g-pre-cc"
							onChange={onChange}
							label="Colon Cancer"
						/>
					</Col>
					<Col className="mb-2" xs={6} md={4}>
						<CustomInput
							type="switch"
							id="g-pre-lc"
							name="g-pre-lc"
							onChange={onChange}
							label="Lung Cancer"
						/>
					</Col>
					<Col className="mb-2" xs={6} md={4}>
						<CustomInput
							type="switch"
							id="g-pre-bc"
							name="g-pre-bc"
							onChange={onChange}
							label="Breast Cancer"
						/>
					</Col>
				</Row>

				<Row className="mt-4">
					<Col xs={6} md={4}>
						<FormGroup>
							<Label
								for="exampleEmail"
								data-tip="Hello asdfd <br />adf asdfsadfsadfsdaf"
							>
								Age
								<i className="fa fa-info-circle px-1" />
								<span className="text-danger">*</span>
							</Label>
							<Input
								type="text"
								name="g-age"
								onChange={onChange}
							/>
							{validator.current.message(
								"age",
								form["g-age"],
								"required|numeric|min:10,num|max:100,num"
							)}
						</FormGroup>
					</Col>
					<Col xs={6} md={4}>
						<FormGroup>
							<Label for="exampleSelect">
								Gender
								<span className="text-danger">*</span>
							</Label>
							<Input
								type="select"
								name="g-sex"
								onChange={onChange}
							>
								<option value="">Select</option>
								<option value="male">Male</option>
								<option value="female">Female</option>
								<option value="other">Other</option>
							</Input>
							{validator.current.message(
								"gender",
								form["g-sex"],
								"required"
							)}
						</FormGroup>
					</Col>
					<Col xs={6} md={4}>
						<FormGroup>
							<Label for="exampleEmail">
								Height
								<span className="text-danger">*</span>
							</Label>
							<Row form>
								<Col xs={6} md={8}>
									<Input
										type="text"
										name="g-ht"
										onChange={onChange}
									/>
									{validator.current.message(
										"height",
										form["g-ht"],
										"required|numeric|min:3,num|max:300,num"
									)}
								</Col>
								<Col xs={6} md={4}>
									<Input
										type="select"
										name="g-ht-unit"
										onChange={onChange}
									>
										<option value="ft">Feet</option>
										<option value="in">Inches</option>
										<option value="cm">Centimeters</option>
									</Input>
								</Col>
							</Row>
						</FormGroup>
					</Col>
					<Col xs={6} md={4}>
						<FormGroup>
							<Label for="exampleEmail">
								Weight
								<span className="text-danger">*</span>
							</Label>
							<Row form>
								<Col xs={6} md={8}>
									<Input
										type="text"
										name="g-wt"
										onChange={onChange}
									/>
									{validator.current.message(
										"weight",
										form["g-wt"],
										"required|integer|min:3,num|max:500,num"
									)}
								</Col>
								<Col xs={6} md={4}>
									<Input
										type="select"
										name="g-wt-unit"
										onChange={onChange}
									>
										<option value="kg">Kilograms</option>
										<option value="pond">Pounds</option>
									</Input>
								</Col>
							</Row>
						</FormGroup>
					</Col>
					<Col xs={6} md={4}>
						<FormGroup>
							<Label for="exampleEmail">Systolic BP (mmHg)</Label>
							<Input
								type="text"
								name="g-sbp"
								onChange={onChange}
								placeholder="90-200"
							/>
							{validator.current.message(
								"systolic bp",
								form["g-sbp"],
								"integer|min:90,num|max:200,num"
							)}
						</FormGroup>
					</Col>
					<Col xs={6} md={4}>
						<FormGroup>
							<Label for="exampleEmail">
								Diastolic BP (mmHg)
							</Label>
							<Input
								type="text"
								name="g-dbp"
								onChange={onChange}
								placeholder="30-140"
							/>
							{validator.current.message(
								"diastolic bp",
								form["g-dbp"],
								"integer|min:30,num|max:140,num"
							)}
						</FormGroup>
					</Col>
					<Col xs={6} md={4}>
						<FormGroup>
							<Label for="exampleEmail">
								Total cholesterol (mg/dL)
							</Label>
							<Input
								type="text"
								name="g-totchol"
								onChange={onChange}
								placeholder="130-320"
							/>
							{validator.current.message(
								"total cholestrol",
								form["g-totchol"],
								"integer|min:130,num|max:320,num"
							)}
						</FormGroup>
					</Col>
					<Col xs={6} md={4}>
						<FormGroup>
							<Label for="exampleEmail">
								HDL cholesterol (mg/dL)
							</Label>
							<Input
								type="text"
								name="g-hdl"
								onChange={onChange}
								placeholder="20-100"
							/>
							{validator.current.message(
								"hdl cholesterol",
								form["g-hdl"],
								"integer|min:20,num|max:100,num"
							)}
						</FormGroup>
					</Col>
					<Col xs={6} md={4}>
						<FormGroup>
							<Label for="exampleEmail">
								LDL cholesterol (mg/dL)
							</Label>
							<Input
								type="text"
								name="g-ldl"
								onChange={onChange}
								placeholder="30-300"
							/>
							{validator.current.message(
								"LDL cholesterol",
								form["g-ldl"],
								"integer|min:30,num|max:300,num"
							)}
						</FormGroup>
					</Col>
					<Col xs={6} md={4}>
						<FormGroup>
							<Label for="exampleEmail">
								Triglycerides (mg/dL)
							</Label>
							<Input
								type="text"
								name="g-tryg"
								onChange={onChange}
							/>
							{validator.current.message(
								"tryglycerides",
								form["g-tryg"],
								"integer|min:00,num|max:20000,num"
							)}
						</FormGroup>
					</Col>
				</Row>
			</Form>
			<BottomNav
				previousStep={props.previousStep}
				nextStep={submitForm}
			/>
		</div>
	);
}
