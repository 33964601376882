import React, { Component } from 'react'

export default class privacyPolicy extends Component {
    render() {
        return (
            <main> 
  <div className="position-relative my-5 pageTitle">
    <img className="img-fluid" src="/img/vetBanner.jpg" alt="inner page bg" />
    <div className="position-absolute w-100 text-center text-white page-tagline">
      <h1 className="w-70 m-auto pt-5 pb-5">Privacy Policy</h1></div></div><div className="container py-5">	
    <p><strong>PLEASE READ CAREFULLY BEFORE SIGNING ENROLLMENT FORM.</strong><br />
      The activdoctors.com website and platform (the “Site”) are owned and operated by ActivDoctors, LLC.</p>
    <p>ADC is committed to maintaining Your confidence and trust with respect to any information, recorded in any form, about an identified individual and/or his pet, or an individual and/or his pet whose identity may be inferred from the information. This includes, for example, name, Pet’s name, picture, e-mail, mailing address, and telephone number, billing and account information, and other information incidental to providing the products or services offered on our Site (“Personal Information”).</p>
    <p>Individuals accessing the Site shall be referred to as “You” “Your” or “Users”. Storage and access to medical records, Second Medical opinion, e-consultation services and other services offered to Users are collectively referred to herein as the “Service” or “Services”. We intend to protect the Personal Information stored on the Site and this privacy statement describes how We intend to do that.</p>
    <p>BY SUBMITTING PERSONAL INFORMATION TO US, BY USING OUR SITE, AND BY USING OUR SERVICES, YOU AGREE THAT WE MAY COLLECT, USE AND DISCLOSE SUCH PERSONAL INFORMATION IN ACCORDANCE WITH THIS PRIVACY POLICY AND AS PERMITTED OR REQUIRED BY LAW.</p>
    <p>If You do not agree with these terms, do not provide any Personal Information to Us. If You refuse or withdraw Your consent, or if You choose not to provide Us with any required Personal Information, We may not be able to provide You with products or services that can be offered on our Site.</p>
    <p>Minors (as defined under the laws of their jurisdiction or residence) are not eligible to register for, use, or purchase the products or Services available on our Site. We do not knowingly collect Personal Information from any Minor, and will not use this information if We discover that it has been provided by a Minor.</p>
    <p>Unless You affirmatively choose to make such Personal Information available to Us by subscribing to our Site, We will not collect and use Your Personal Information without Your knowledge and permission.</p>
    <p>We will not knowingly disclose Your Personal Information to third parties, except as provided in this Privacy Policy.</p>
    <p>We will allow You to view, correct or remove Your information.<br />
      We will take reasonable steps to protect the security of the Personal Information We collect from You.</p>
    <p>We collect and use the Personal Information provided through our Site at the time of User’s subscription. Our Site also describes the choices available to Users regarding our use of the Personal Information. Although We will not share Your name or email address with any third party, unless You specifically authorize Us to do so, please be aware that if You do not permit Us to share Your name and other personal information, You may not be able to subscribe to certain membership plans or receive the services and benefits offered in connection with Your subscription.</p>
    <p>Occasionally, We conduct customer satisfaction surveys so that our Users can provide feedback on their experience using our Site, Services and programs. Contact information requested in these surveys is optional. We use the survey responses to improve our programs and services to our Users. We also occasionally share this survey data with third parties, but only in aggregate form without disclosing Personal Information or identifying individual Users.</p>
    <p>From time to time, We will also offer Users the opportunity to receive information on various health-related products and services offered on our Site by Us and third-parties. We will not share Your Personal Information with these third parties, other than Service providers, unless We have Your explicit permission to do so. Your permission, however, may be assumed by Your requesting access to or enrolling to receive these Services.</p>
    <p>We will use information submitted through our online registration to analyze the characteristics of our Users, in order to improve our Services to them. We will occasionally utilize aggregated statistics about our Users in conducting research and preparing reports made available in-house, to third parties, or to the general public. These reports will not identify individual Users.</p>
    <p>For more information about Our programs and Services, click on the “Contact Us” link. We will collect Your name, email address, phone number, and additional information, which we will use to contact You about Your request. We will not share the information You provide with third parties without Your permission.</p>
    <p><strong>USER ACCOUNT, PASSWORD, AND SECURITY</strong><br />
      User will establish a user name and password upon completing the registration process. User is responsible for maintaining the confidentiality of the Access Code and is fully responsible for all activities that occur under User’s Access Code. User is responsible for taking all reasonable steps to ensure that no unauthorized person shall have access to User’s Access Code. It is User’s sole responsibility to control the dissemination of User’s Access Code, to authorize, monitor, and control access to and use of User’s Records, account, and Access Code, and to promptly inform ADC of any need to deactivate or reset an Access Code.</p>
    <p><strong>HOW TO ACCESS, MODIFY OR DELETE YOUR PERSONAL INFORMATION</strong><br />
      We endeavor to ensure that Personal Information is as accurate, complete, and current as necessary for the purposes for which we use that information. We encourage You to login to Your member account by visiting this page https://login.activdoctors.com/Account/Login tab to review Your Personal Information.</p>
    <p>You can access, update, correct, or modify Your Personal Information by logging on with Your User name, password and modifying Your Personal Information.</p>
    <p>If You request that we remove all of the Personal Information stored on Our Site, We will promptly do so, to the extent possible. Once Personal Information is removed, however, You will not be able to use many of the Services available on Our Site unless You re-register.</p>
    <p>Please remember that Our healthcare records storage program is subject to certain Terms and Conditions and right to access, modify, or delete those records are subject to those Terms and Conditions.</p>
    <p><strong>COOKIES</strong><br />
      A “cookie” is a small data file that a website can place on Your computer’s hard drive in order, for example, to collect information about Your activities on the Site or to make it easier to use certain Site functions. The cookie transmits this information back to the website’s computer. Many Users do not know that “cookies” are being placed on their computers when they visit websites. If You want to know when this happens, or prevent it from happening, You can set Your browser to warn You when a website attempts to place a “cookie” on Your computer.</p>
    <p>We use cookies on Our Sites in the following ways:</p>
    <p>When You respond to a survey that We post from time to time, we use a cookie to make it easier for You to use the survey form, and to ensure that we do not receive duplicate survey responses from any one respondent.</p>
    <p>When You register to become a User, we use a cookie to enable You to navigate easily through the application process.</p>
    <p>We also use cookies to analyze how Users, in the aggregate, access Our Site, in order to make Our Site as useful as possible for them. We do not use cookies to collect or store personally identifiable information.</p>
    <p><strong>INFORMATION AUTOMATICALLY COLLECTED BY OUR SITE</strong><br />
      We automatically collect certain non-personally identifying information, such as Your computer’s IP address, the type of browser in use, and pages viewed when You visit the Site. We use this information to understand how Users navigate through the Site, to enhance Your experience while using the Site, and to make the materials we post as valuable to Users as possible. We do not link this information to personally identifiable information.</p>
    <p><strong>PERSONAL INFORMATION SHARING</strong><br />
      We will share Your Personal Information with third parties in the ways that are described in this Privacy Policy. We will also share Your Personal Information if we are required to do so by law, or if we believe that doing so is necessary in order to conform to the law, cooperate with law enforcement agencies, comply with legal process served on Us, or otherwise protect Our legal rights. From time to time we employ Service providers to perform various functions involving Personal Information on Our behalf. These Service providers are prohibited from using Your Personal Information for any other purpose.</p>
    <p>You can share Your Personal Information by allowing other individuals to access Your account information at Your sole discretion and responsibility. If You share Your information with others, You can view a list of who has access to Your information and You can revoke sharing privileges at any time. When You revoke someone’s ability to read Your health information, that party will no longer be able to read Your information, but may have already seen or may have retained a copy of the information.</p>
    <p>You can completely delete Your Personal Information at any time. Such deletions will take immediate effect in Your account. Backup copies of deleted information may exist for up to two weeks after the data is deleted. Since deleted data will not be restored, You may want to print information before deleting it.</p>
    <p><strong>SECOND MEDICAL OPINION AND E-CONSULTATION SERVICE</strong><br />
      We are committed to protecting the privacy of information we gather about you while providing second medical opinion service or e-consultation service as defined in the Terms and Conditions.</p>
    <p>The privacy practices described in this Notice will be followed concerning the second medical opinion service and/or e-consultation service by: (1) the medical director who receives your pet’s medical records and proposes the veterinary (2) the consulting veterinary who reviews your pet’s medical records and gives second medical opinion or e-consultation (3) any employee, medical staff, trainee, or volunteer of ADC</p>
    <p><strong>SECURITY</strong><br />
      Providing security for the Personal Information Users submitted to Our Site is one of Our top priorities. Although we use encryption technology for most communications, including information Users may provide through the registration process and in utilizing any of the Services, You should understand that no transmission of information to the Site can be entirely secure. Therefore, always use caution when submitting Personal Information online. We take commercially reasonable steps to provide security for Your Personal Information once we receive it.</p>
    <p><strong>LINKS TO OTHER WEBSITES</strong><br />
      The Site has links to other websites whose privacy practices may differ from Our Privacy Policy. If You allow another website to access Your health information and to store a copy of Your Personal Information, that copy will be governed by that website’s privacy policy. If You submit Personal Information to any of those sites, the privacy and security of Your Personal Information is subject to their privacy statements. We are not responsible for the content, performance, or privacy policies of third-party websites. We encourage You to carefully read the privacy statement of any other website You visit.</p>
    <p><strong>CHANGES IN THIS PRIVACY POLICY</strong><br />
      If we change this Privacy Policy in ways that affect the personally identifying information we have collected through Our Site, we will post those changes in this space and advise You of choices You may have as a result of those changes. We will also post a notice on Our home page that this Privacy Policy has changed.</p>
    <p><strong>CONTACT US</strong><br />
      If You submit Personal Information, such as Your email address, to us by following the “Contact Us” link located throughout Our Site, we will use that Personal Information to respond to Your question or request for information. We will not share Your Personal Information with third parties without Your explicit permission.</p>
    <p>If You have any questions or concerns about (a) access to Personal Information, (b) the collection, use, management or disclosure of Personal Information, (c) this Privacy Policy, or (d) with whom Your Personal Information is shared, or if You need further assistance with respect to Our Privacy Policy, You may contact Our Customer Service department as indicated below. We will attempt to respond to Your questions or concerns promptly.</p>
    <p>E-mail: admin@activdoctors.com</p>
  </div>
</main>

        )
    }
}
