import config from "data/config";
import React, { useState } from "react";
import { PayPalButton } from "react-paypal-button-v2";

export default function PayPal({
	amount = 0,
	onComplete = ({ data, details }) => console.log(data, details),
}) {
	const [btnReady, setBtnReady] = useState(false);

	return (
		<div style={{ maxWidth: "340px" }}>
			<PayPalButton
				amount={amount}
				// shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
				onSuccess={(details, data) => onComplete({ details, data })}
				onButtonReady={() => setBtnReady(true)}
				catchError={(err) => console.log(err)}
				onCancel={(data) => console.log(data)}
				options={{
					clientId: config.PAYPAL_CLIENT_ID,
				}}
				//https://developer.paypal.com/docs/checkout/integration-features/customize-button/
				style={{ color: "blue" }}
			/>
			{!btnReady && <div className="">loading...</div>}
		</div>
	);
}
