import { gqlSend } from "utils/AxiosUtil";

export const getUserIdFromHash = (hash) => {
	const q = {
		query: `
        {
            users(where: {hash: {_eq: "${hash}"}}) {
               id
            }
        }`,
	};
	return gqlSend(q);
};
