import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";
import { pet_types } from "data/config/variables";
import SimpleReactValidator from "simple-react-validator";
import FormElement from "pages/components/form/FormElement";
import { insertOrUpdatePet, deletePet } from "./network";

class AddPet extends Component {
    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.toggle = this.toggle.bind(this);
        this.validator = new SimpleReactValidator({});
        this.setInitialState(true);
    }

    setInitialState(fromConstructor = false) {
        let { data, showModal, userId } = this.props;
        let { id, name, type, breed, notes } = data || {};
        let obj = {
            modal: showModal,
            id,
            name,
            type,
            breed,
            notes,
            userId,
            thankYou: false, // success msg
            submitBtnTxt: "Submit",
            serverErrors: false, // for displaying errors from server
            serverErrorMessage: "Sorry! Something went wrong" // error message frm server
        };
        if (fromConstructor) this.state = obj;
        else this.setState(obj);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.showModal !== this.props.showModal) this.setInitialState();
    }

    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSelectChange(sel) {
        let s = sel.map(item => item.value);
    }

    onFormSubmit(e) {
        e.preventDefault();

        if (this.validator.allValid()) {
            this.setState({
                submitBtnTxt: "Please Wait...",
                serverErrors: false,
                serverErrorMessage: ""
            });
            let { id, name, type, breed, notes, userId } = this.state;
            let params = { id, name, type, breed, notes, userId };

            insertOrUpdatePet(params)
                .then(r => {
                    console.log(r);
                    if (r.data.insert_pets) {
                        const [pet] = r.data.insert_pets.returning;
                        params.id = pet.id;
                        params.phr_id = pet.phr_id;
                    }
                    this.props.onItemUpdated(params);
                    this.setState({ thankYou: true });
                    setTimeout(() => this.toggle(), 500);
                })
                .catch(err => {
                    this.setState({
                        submitBtnTxt: "Submit",
                        serverErrors: true,
                        serverErrorMessage: "Sorry! Something went wrong. Please try again later."
                    });
                });
        } else {
            this.validator.showMessages();
            this.forceUpdate(); // [validator] rerender to show messages for the first time
        }
        return false;
    }

    onDelete(e) {
        e.preventDefault();
        const { id } = this.state;
        deletePet(id)
            .then(r => {
                this.props.onItemUpdated({ id, delete: true });
                this.setState({ thankYou: true });
                setTimeout(() => this.toggle(), 500);
            })
            .catch(err => {
                this.setState({
                    submitBtnTxt: "Submit",
                    serverErrors: true,
                    serverErrorMessage: "Sorry! Something went wrong. Please try again later."
                });
            });
    }

    render() {
        const warningBlock = () => (
            <div className="ml-3 alert alert-danger">
                <strong className="mr-2">Error!</strong> {this.state.serverErrorMessage}
            </div>
        );
        const successBlock = () => (
            <div className="ml-3 alert alert-success">
                <strong className="mr-2">Submitted successfully!</strong>
            </div>
        );

        const formData = () => (
            <div className="row">
                {this.state.thankYou && successBlock()}
                {this.state.serverErrors && warningBlock()}
                <FormElement
                    className="col-md-12"
                    name="name"
                    placeHolder="Pet Name"
                    state={this.state}
                    onChange={this.onChange}
                    validator={this.validator}
                    validation="required"
                />
                <FormElement
                    name="type"
                    className="col-md-12"
                    state={this.state}
                    validator={this.validator}
                    validation="required"
                >
                    <Select
                        name="type"
                        options={pet_types}
                        isMulti={false}
                        placeholder="Pet Type"
                        value={pet_types.filter(op => (this.state.type === op.value ? op : false))}
                        onChange={sel =>
                            this.setState({
                                type: sel.value
                            })
                        }
                    />
                </FormElement>
                <FormElement
                    name="breed"
                    className="col-md-12"
                    placeHolder="Breed [Optional]"
                    state={this.state}
                    onChange={this.onChange}
                    validator={this.validator}
                    validation=""
                />
                <FormElement
                    name="notes"
                    className="col-md-12"
                    state={this.state}
                    validator={this.validator}
                    validation=""
                >
                    <textarea
                        name="notes"
                        rows="2"
                        className="form-control"
                        placeholder="Notes"
                        value={this.state.notes}
                        onChange={this.onChange}
                    />
                </FormElement>
            </div>
        );

        const { className, onClosed } = this.props;
        return (
            <div>
                <Modal
                    isOpen={this.state.modal}
                    onClosed={e => {
                        if (onClosed) onClosed();
                    }}
                    toggle={this.toggle}
                    className={className}
                >
                    <ModalHeader toggle={this.toggle}>{this.state.id ? "Update Pet" : "Add Pet"}</ModalHeader>
                    <ModalBody>{formData()}</ModalBody>
                    <ModalFooter className="justify-content-start">
                        <Button color="primary" onClick={this.onFormSubmit}>
                            {this.state.submitBtnTxt}
                        </Button>
                        <Button color="outline-secondary" onClick={this.toggle}>Cancel</Button>
                        {this.state.id && (
                            <Button className="ml-auto" color="danger" onClick={this.onDelete}>
                                Delete
                            </Button>
                        )}
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default AddPet;
