import React, { Component } from "react";
import Auth, { AuthModes } from "../auth";
import { getUserId, getUser } from "utils";
import isEmpty from "utils/isEmpty";
import FormElement from "../form/FormElement";
import SimpleReactValidator from "simple-react-validator";
import Button from "../form/Button";
import {
    A4P_ROLE_VENDOR,
    A4P_ROLE_USER,
    APP_IS_PETS_ENABLED
} from "data/config/variables";
import { searchUserByEmail } from "./network";
import jwt_decode from "jwt-decode";
import MyPets from "../MyPets";

export default class BookAppointmentInfo extends Component {
    constructor(props) {
        super(props);
        this.validator = new SimpleReactValidator({});

        this.state = {
            email: null,
            pet: null,
            searchResult: null,
            searchByEmail: true,
            searchByEmailError: false,
            searchingUser: false,
            summary: this.props.info ? this.props.info.summary : ""
        };
        this.searchUser = this.searchUser.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value }, this.onUpdate);
    }

    searchUser() {
        const { email } = this.state;
        this.setState({ searchUser: true });
        searchUserByEmail(email)
            .then(r => {
                const [user] = r.data.users;
                if (!isEmpty(user)) {
                    this.setState({ searchUser: false, searchResult: user });
                } else
                    this.setState({
                        searchUser: false,
                        searchByEmailError: true
                    });
            })
            .catch(err => console.log(err));
    }

    onSubmit(user) {
        const { summary, pet } = this.state;
        if (this.validator.allValid()) {
            this.props.onComplete({ user, pet, summary })
        } else {
            this.validator.showMessages();
            this.forceUpdate(); 
        }
    }

    InfoForm = user => {
        const { summary, pet } = this.state;
        return (
            <div>
                <p className="text-muted">Welcome {user.first_name}!</p>
                <div className="row">
                    {APP_IS_PETS_ENABLED && (
                        <div className="col-12">
                            <MyPets
                                userId={user.id}
                                onPetSelected={pet => this.setState({ pet })}
                            />
                            {this.validator.message( "Pet", pet, "required")}
                        </div>
                    )}
                    <p className="col-12 mt-4">
                        Reason for consult:
                    </p>
                    <FormElement
                        name="summary"
                        className="col-md-12"
                        state={this.state}
                    >
                        <textarea
                            key="ta1"
                            name="summary"
                            rows="3"
                            value={summary}
                            className="form-control"
                            placeholder=""
                            onChange={this.onChange}
                        />
                        {this.validator.message( "information", summary, "required")}
                    </FormElement>
                </div>
                <Button
                    btnCls="btn-outline-primary"
                    value="Continue"
                    onClick={e => this.onSubmit(user)}
                />
            </div>
        );
    };

    render() {
        const {
            searchingUser,
            searchByEmail,
            searchResult,
            searchByEmailError,
            summary
        } = this.state;

        // set defaults
        let user = searchResult || getUser();
        let userRole = A4P_ROLE_USER;
        if (user && user.role === A4P_ROLE_VENDOR) {
            userRole = A4P_ROLE_VENDOR;
        }

        return (
            <div>
                <div className="my-3">
                    <p className="h6 text-muted">
                        {`${
                            userRole === A4P_ROLE_USER ? "Your" : "User"
                        } Information`}
                        :
                    </p>
                </div>
                <div className="p-3 border">
                    {userRole === A4P_ROLE_USER &&
                        (isEmpty(user) ? (
                            <div className="col-md-6 col-sm-12">
                                <Auth
                                    mode={AuthModes.signup}
                                    showCaptcha={false}
                                    showCoupon={false}
                                    redirectUrl={null}
                                    onComplete={data =>
                                        this.setState({ autComplete: true })
                                    }
                                />
                            </div>
                        ) : (
                            this.InfoForm(user)
                        ))}

                    {userRole === A4P_ROLE_VENDOR &&
                        (isEmpty(searchResult) ? (
                            searchByEmail ? (
                                <div className="col-md-6 col-sm-12">
                                    <h4 className="mt-2 mb-4 text-center">
                                        Search By Email
                                    </h4>
                                    <FormElement
                                        name="email"
                                        label="Enter Email"
                                        state={this.state}
                                        onChange={this.onChange}
                                    />
                                    {searchByEmailError && (
                                        <p className="mb-2 text-danger">
                                            Customer not found
                                        </p>
                                    )}
                                    <div>
                                        <Button
                                            btnCls="btn-primary px-4"
                                            value="Search User"
                                            loading={searchingUser}
                                            onClick={this.searchUser}
                                        />
                                    </div>

                                    <div className="text-left mt-4">
                                        Customer not registered?
                                        <a
                                            href="#!"
                                            className="ml-2"
                                            onClick={e => {
                                                e.preventDefault()
                                                this.setState({
                                                    searchByEmail: false
                                                })
                                            }}
                                        >
                                            <strong>Click here!</strong>
                                        </a>
                                    </div>
                                </div>
                            ) : (
                                <div className="col-md-6 col-sm-12">
                                    <Auth
                                        mode={AuthModes.signup}
                                        showCaptcha={false}
                                        showCoupon={false}
                                        redirectUrl={null}
                                        showLoginLink={false}
                                        autoLogin={false}
                                        onComplete={data => {
                                            const _d = jwt_decode(data.token);
                                            this.setState({
                                                searchResult: _d.user
                                            });
                                        }}
                                    />

                                    <div className="text-center link_bright mb-2">
                                        Customer alraedy has an account?
                                        <a
                                            href="#!"
                                            className="ml-2"
                                            onClick={e => {
                                                e.preventDefault()
                                                this.setState({
                                                    searchByEmail: true
                                                })
                                            }}
                                        >
                                            <strong>Click here!</strong>
                                        </a>
                                    </div>
                                </div>
                            )
                        ) : (
                            this.InfoForm(user)
                        ))}
                </div>
            </div>
        );
    }
}
